/* Banner */

p {
    color: #555;
    font-family: Bahnschrift, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    /* 143.75% */
}

h3 {
    color: #000;
    font-family: Bahnschrift, sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    /* 100% */
}

.abs-banner .left h2 {
    color: #1351C1;
    font-family: Bahnschrift, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    position: relative;
    margin-bottom: 1.3rem;
}

.abs-banner .left h2::after {
    content: "";
    width: 13%;
    height: 5px;
    background-color: #1351C1;
    position: absolute;
    left: 0;
    bottom: -0.2rem;
    border-radius: 0.4rem;

}

.abs-banner .left h6 {
    color: #555;
    font-family: Bahnschrift, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    /* 143.75% */
}

.abs-banner .left p {
    color: #555;
    font-family: Bahnschrift, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    width: 53%;
    /* 143.75% */
}

.abs-banner .left .social-icons a svg {
    width: 32px;
    height: 32px;
    color: #1351C1;
}

.abs-banner .left .social-icons a:last-child svg {
    width: 40px;
    height: 40px;
}

.abs-banner .left .social-icons a svg path {
    fill: #1351C1;
}

/* bottom banner  */

.abs-banner .banner-bottom .journy-hp {
    background-color: #1351C1;
    padding: 2rem 3rem;
    width: 100%;
    /* min-width: 1100px; */
    margin: auto;
    position: relative;

}

.abs-banner .banner-bottom .overflow-div {
    max-width: 90%;
    overflow-x: auto;
    margin: auto;
    margin-top: -3rem;
    z-index: 2;
    border-radius: 8px;
}

.abs-banner .banner-bottom .journy-hp h5 {
    color: #FFF;
    font-family: Bahnschrift, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    width: 25%;

    /* 100% */
}

.abs-banner .banner-bottom .journy-hp h5.hide-in-desk {
    display: none;
}

.abs-banner .banner-bottom .journy-hp>div {
    padding: 0.5rem 1rem;
    width: 17%;
}

.abs-banner .banner-bottom .journy-hp>div:not(:last-child) {

    border-right: 1px solid #fff;
}

.abs-banner .banner-bottom .journy-hp p {

    color: #FFF;
    font-family: Bahnschrift, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 100% */
}

.abs-banner .banner-bottom .journy-hp span {

    color: #FFF;
    font-family: Bahnschrift, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    /* 131.25% */
}

.abs-banner .banner-body .right>img {
    width: 648px;
    height: 447px;
}

.abs-banner .banner-bottom .bannerb-cont p.hpara {
    color: #000;
    text-align: center;
    font-family: Bahnschrift, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 2rem;
}

.abs-banner .banner-bottom .bannerb-cont p.hpara span {
    color: #1351C1;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.abs-banner .banner-bottom .bannerb-cont h4 {
    color: #000;
    text-align: center;
    font-family: Bahnschrift, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
}

.abs-banner .banner-bottom .bannerb-cont .key-point svg {
    height: 30px;
    width: 30px;
}

.abs-banner .banner-bottom .bannerb-cont .key-point svg path {
    fill: #1351C1;
}

/*Stat About Compnent2 -------------------- */
.about-comp2 .abc-body .btns button {
    color: #555;
    font-family: Bahnschrift, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 1rem;
}

.about-comp2 .abc-body .btns {
    border-bottom: 1px solid #BFBFBF;
    min-width: 800px;
}

.about-comp2 .abc-body .overf-div {
    max-width: 100%;
    overflow-x: auto;
}

.about-comp2 .abc-body .overf-div::-webkit-scrollbar {
    display: none;
}

.active-btn {
    color: #002a5b !important;
    border-bottom: 2px solid #002a5b;
}

.about-comp2 .abc-body .our-comp h5 {
    color: #002a5b;
    font-family: Bahnschrift, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.about-comp2 .abc-body .our-comp {
    min-height: 400px;
    background: url("../../public/assets/images/our-comp-svg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

}

.about-comp2 .abc-body .our-comp .left {
    width: 48%;
}

.about-comp2 .abc-body .our-comp h6 {

    color: #555;
    font-family: Bahnschrift, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    /* 143.75% */
}

.about-comp2 .abc-body .our-comp .right img {
    width: 432px;
    height: 280px;
}


.about-comp2 .abc-body .core-value h4 {
    color: #1351C1;
    font-family: Bahnschrift, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.about-comp2 .abc-body .core-value .card {
    width: 30%;
   padding: 15px;
   margin: 10px;
}

.about-comp2 .abc-body .core-value .card .title {

    color: #1351C1;
    font-family: Bahnschrift, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.about-comp2 .abc-body .core-value .card p {}

.about-comp2 .abc-body .core-value .card>img {
    width: 35px;
    height: 35px;
    padding: 0.3rem 0.4rem;
    border-radius: 0.4rem;
    background-color: #FFAE00;

}

.about-comp2 .abc-body .milestone h4 {
    color: #000;
    text-align: center;
    font-family: Bahnschrift, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.about-comp2 .abc-body .milestone h4 .spn1 {
    color: #1351C1;
    ;
}

.about-comp2 .abc-body .milestone h4 .spn2 {
    color: #FFAE00;
    ;
}

/*End About Compnent2 -------------------- */

/* Start About Component 3 --------------------- */
.about-comp3 .left {
    width: 50%;
}

.about-comp3 .left img {
    padding: 0.7rem 0.8rem;
    background: #1351C1;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    flex-shrink: 0;
}

.about-comp3 .left p.bpara {
    color: #000;
    font-family: Bahnschrift, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-top: 0.7rem;
    margin-bottom: 0;
}

.about-comp3 .right>img {
    width: 494px;
    height: auto;
}
.about-comp3 .right .r-content{
 background-color: #000;
 width: fit-content;
 border-radius: 0.4rem;
 padding: 1rem 1.8rem;
 position: absolute;
 top: 1rem;
 left: 1rem;
}
.about-comp3 .right .r-content p {
    color: #FFF;
    font-family: Bahnschrift, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 0;
    /* 69.231% */
}

.about-comp3 .right .r-content p span {
    font-size: 30px;
}


/* End About Component 3 --------------------- */

/* AboutComp4 --------------------*/
.about-comp4 .abs-tcontent h4.logo {
    color: #555;
    font-family: Italiana;
    font-size: 66px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 1.5rem auto;
}

.about-comp4 .cards .card {
    text-align: center;
    width: 19%;
    border-radius: 0.6rem;
}



.about-comp4 .cards {
    min-width: auto;
   
}
.core-value .cards{
    display: flex;
    justify-content: space-between;
   
}

.about-comp4 .overf2-div {
    max-width: 100%;
    overflow-x: auto;
}

.about-comp4 .overf2-div::-webkit-scrollbar {
    display: none;
}


.about-comp4 .cards .card img {
    width: 100%;
    height: 150px;
    border-radius: 0.6rem 0.6rem 0 0;
}

.about-comp4 .cards h5.title {
    color: #000;
    text-align: center;
    font-family: Bahnschrift, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.about-comp4 .abs-tcontent {
    margin-bottom: 4rem;
}


/* About  Component5 */

.about-comp5 .left {
    width: 50%;
}

.about-comp5 .left h3 {
    margin-bottom: 2rem;
}

.about-comp5 .left .accordion-button,
.about-comp5 .left .Collapsible .Collapsible__trigger {
    color: #000;
    font-family: Bahnschrift, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    width: 100%;
    display: inline-block;
    padding: 1rem 1.3rem;
    border-collapse: collapse;

}

.about-comp5 .left .Collapsible {
    border-radius: 0.15rem;
    margin: 0.7rem auto;
    border: 1px solid #DFDFDF;
    background: #FFF;
    position: relative;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10)
}

.about-comp5 .left .Collapsible::after {
    content: "^";
    font-size: 1.5rem;
    font-weight: 700;
    position: absolute;
    right: 1rem;
    top: 0.5rem;
}

.Collapsible__contentInner {
    padding-top: 1rem 1.3rem;
    padding: 1rem 1.3rem;
    border-top: 1px solid #DFDFDF;
}



.about-comp5 .right>img {
    width: 530px;
    height: 510px;
}

.about-comp5 .right .support {
    border: 1px solid red;
    width: fit-content;
    padding: 1rem;
    position: absolute;
    right: 0;
    border: 2rem;
    bottom: 0;
    background: #1351C1;
    color: #fff;
}

.about-comp5 .right .support h6 {

    color: #FFF;
    font-family: Bahnschrift, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.about-comp5 .right .support p {

    color: #FFF;
    font-family: Bahnschrift, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}




/* @Media Start */


@media(max-width: 1299px) {
    .abs-banner .banner-bottom .journy-hp h5 {
        font-size: 28px;
        width: 23%;
        line-height: 33px;
    }

    .abs-banner .banner-bottom .journy-hp span {
        font-size: 28px;
        line-height: 36px;

    }

    .abs-banner .banner-bottom .journy-hp p {
        font-size: 15px;
        margin-bottom: 0.7rem;
    }

    .abs-banner .banner-bottom .journy-hp>div {
        padding: 0.5rem;

    }

    .abs-banner .banner-bottom .journy-hp {
        padding: 1.5rem 2rem;
    }
}



@media(max-width:1199px) {

    h3 {
        font-size: 38px;
    }

    .abs-banner .banner-body .left {
        margin-bottom: 2rem;
    }

    .abs-banner .left h2 {
        font-size: 40px;
        margin-bottom: 1rem;
    }

    .abs-banner .left p {
        width: 80%;
    }

    .abs-banner .banner-bottom .journy-hp h5 {
        font-size: 26px;
    }

    .abs-banner .banner-bottom .bannerb-cont p.hpara,
    .abs-banner .banner-bottom .bannerb-cont h4 {
        font-size: 27px;
    }

    .abs-banner .left h2::after {
        width: 20%;
    }

    .abs-banner .banner-body .right>img {
        height: 360px;
    }

    .about-comp2 .abc-body .milestone h4 {
        font-size: 38px;
    }

    .about-comp3 .right>img {
        width: 409px;
        height: 400px;
    }

    .about-comp4 .cards .card {
        width: 15%;
    }

    .about-comp5 .right>img {
        width: 425px;
        height: 440px;
    }

    .about-comp5 .left .accordion-button {
        font-size: 27px;
    }

    .about-comp5 .right .support>svg {
        width: 60px;
        height: 60px;
    }

    .about-comp5 .right .support h6 {
        font-size: 26px;
    }




}

@media(max-width: 992px) {

    h3 {
        font-size: 33px;
        line-height: 35px;
    }

    .abs-banner .left .social-icons a svg {
        width: 23px;
        height: 23px;
    }

    .abs-banner .banner-bottom .journy-hp h5 {
        font-size: 22px;
    }

    .abs-banner .banner-bottom .journy-hp span {
        font-size: 23px;
        line-height: 26px;
    }

    .abs-banner .banner-bottom .journy-hp {
        padding: 1rem 2rem;
    }

    .abs-banner .left .social-icons a:last-child svg {
        width: 32px;
        height: 32px;
    }

    .abs-banner .left p {
        margin-bottom: 0.3rem;
    }

    .abs-banner .banner-bottom .bannerb-cont p.hpara {
        font-size: 23px;
    }

    .abs-banner .banner-bottom .overflow-div {
        margin-top: -2rem;
    }

    .abs-banner .left h2 {
        font-size: 36px;
    }

    .abs-banner .banner-body .right>img {
        height: 300px;
    }

    /* About Comp2 */
    .about-comp2 .abc-body .btns button {
        font-size: 18px;
        padding: 0.8rem 0.5rem;
    }

    .about-comp2 .abc-body .our-comp .right img {
        width: 340px;
        height: 232px;
    }

    .about-comp2 .abc-body .milestone h4 {
        font-size: 30px;
    }

    /* About Comp3 */
    .about-comp3 .left img {
        padding: 0.3rem 0.4rem;
        width: 40px;
        height: 40px;
    }

    .about-comp3 .right>img {
        width: 359px;
        height: auto;
    }

    /* About comp4 */
    .about-comp4 .abs-tcontent h4.logo {
        font-size: 54px;
        margin: 1rem auto;
    }

    .about-comp4 .abs-tcontent {
        margin-bottom: 3rem;
    }

    .about-comp5 .right>img {
        width: 355px;
        height: auto;
    }

    .about-comp5 .left .accordion-button {
        font-size: 22px;
    }
}


@media(max-width: 767px) {
    .abs-banner .left h2 {
        font-size: 30px;
    }

    .abs-banner .banner-body .right>img {
        height: 260px;
        width: 400px;
    }

    .abs-banner .left p {
        width: 100%;
    }

    .abs-banner .banner-bottom .bannerb-cont .key-point svg {
        height: 25px;
        width: 25px;
    }

    .abs-banner .banner-bottom .bannerb-cont h4 {
        font-size: 24px;
    }

    .about-comp2 .our-comp,
    .about-comp3>div,
    .about-comp5 .abc5-body {
        flex-direction: column-reverse;
        gap: 1.5rem;
    }

    .about-comp2 .abc-body .our-comp .left,
    .about-comp3>div .left,
    .about-comp5 .abc5-body .left {
        width: 100%;
    }

    .about-comp2 .abc-body .our-comp .right img,
    .about-comp3>div .right>img,
    .about-comp5 .abc5-body .right>img {
        width: auto;
    }

    /* .about-comp2 .abc-body .btns button {
        font-size: 16px;
        padding:  0.5rem;
    } */

    /* About comp4 */
    .about-comp4 .abs-tcontent h4.logo {
        font-size: 47px;
        margin-bottom: 0.3rem;
    }

    .about-comp2 .abc-body .core-value .card .title {
        font-size: 16px;
    }

    .about-comp2 .abc-body .core-value .card p {
        font-size: 14px;
    }

    .about-comp2 .abc-body .milestone h4 {
        font-size: 24px;
    }
}

@media(max-width: 600px) {
    .abs-banner .banner-body>div:first-child {
        flex-direction: column-reverse;
        gap: 1rem;
    }

    .abs-banner .banner-bottom .overflow-div {
        margin-top: 0rem;
    }

    .abs-banner .banner-bottom .overflow-div {
        max-width: 100%;
    }

    .abs-banner .banner-bottom .bannerb-cont p.hpara {
        font-size: 20px;
        text-align: justify;
    }

    .abs-banner .banner-bottom .bannerb-cont h4 {
        font-size: 22px;
    }

    .abs-banner .banner-bottom .bannerb-cont .key-point svg {
        height: 20px;
        width: 20px;
    }

    .about-comp2 .abc-body .core-value .card {
        width: 48%;
    }

    .about-comp2 .abc-body .milestone h4 {
        font-size: 21px;
    }
}

@media(max-width:490px) {
    .abs-banner .banner-bottom .bannerb-cont h4 {
        font-size: 16px;
    }

    .abs-banner .banner-bottom .bannerb-cont p.hpara {
        font-size: 18px;
        margin-bottom: 1.5rem;
    }
}


@media(max-width: 416px) {
    .about-comp2 .abc-body .core-value .card {
        width: 100%;
    }
}
.feature-content{
    width: 100%;
    margin-left: 30px;
    margin-top: -22px;
}