@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&family=Rubik:wght@400;500;600;700;800&display=swap');


/*Blog, Media-Press and Our Team CSS, Events   */

.blogs-parent {
    font-family: Bahnschrift, sans-serif;
}

.blogs-parent .left {
    width: 67%;
}

.blogs-parent .right {
    width: 30%;
    border: 1px solid #D0CACA;
    border-radius: 10px;
    padding: 10px;
}
.blogs-parent .card-content{
    border: 1px solid #D0CACA;
    border-radius: 10px;
    padding: 10px;
}
.blogs-parent .card-content:not(:last-child) {
    border-bottom: 1px solid #D0CACA;
}

.blogs-parent .card-content>img {
    /* min-width: 300px; */
    width: 280px !important;
    height: 179px;
    border-radius: 3px;
    overflow: hidden;
    
}
.card-content .card-rc{
    width: 65%;
    display: flex;
    flex-direction: column;
}

.blogs-parent .card-content h3 {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.blogs-parent .card-content p {
/* width: 100%; */

    color: #555;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    margin: 0;
   

}

.blogs-parent .card-content h5 {
    width: 100%;
    color: #000;
    font-family: Bahnschrift, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}

/* Recent Posts */
.blogs-parent .right h4 {
    color: #002a5b;
    font-size: 22px;
    font-weight: 600;
}

.blogs-parent .right .search form {
    border: 1px solid #E2E2E2;
    position: relative;
    border-radius: 0.4rem;
    margin: 1rem auto 1rem auto;

}

.blogs-parent .right .search form input {
    width: 100%;
    padding: 0.6rem;
    border: none;
    border-radius: 0.4rem;
}

.blogs-parent .right .search form input:focus-visible {
    outline: none;
}

.blogs-parent .right .search form span {
    position: absolute;
    right: 1rem;
    top: 0.4rem;
    font-size: 1.1rem;
    color: #1351C1;

}

.blogs-parent .right .recent-posts .rp-card {
    padding: 1rem 0;
}

.blogs-parent .right .recent-posts .rp-card img {
    width: 80px;
    height: 60px;
}

.blogs-parent .right .recent-posts .rp-card:not(:last-child) {
    border-bottom: 1px solid #E2E2E2;

}

.blogs-parent .right .recent-posts .rp-card p {

    color: #000;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
}

.blogs-parent .right .recent-posts .rp-card p.bold-para {
    color: #000;
    font-size: 14px;
    font-weight: 600;
}


/*---------------------------------------------- 
|Media & Press Css
---------------------------------------------------*/


.media-press .mb-body .card {
    width: 23%;
}

.media-press .mb-body .card p {
    color: #555;
    font-family: Bahnschrift, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.media-press .mb-body .card a {
    color: #000;
    font-family: Bahnschrift, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.media-press .mb-body .card>img {
    max-width: 293px;
    max-height: 250px;
}

/* End Media press */

/* Start Our Team  --------------- */
.our-team {
    background-color: #ffffff;
    padding: 2rem 0;
}

.our-team .top-content {
    padding: 1.5rem;
    margin: 2rem auto;
    margin-bottom: 0px;
}


.our-team .top-content h3 {
    color: #002a5b;
    text-align: center;
    font-family: Bahnschrift, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.our-team .top-content h3>span {
    color: #002a5b;
    font-weight: 700;
}

.our-team .top-content p {
    color: #002a5b;
    text-align: center;
    font-family: Bahnschrift, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 80%;
    margin: auto;
}

.our-team .top-content .bottom-line {
    width: 161px;
    height: 2px;
    background-color: #002a5b;
    margin: 1rem auto;
}


.our-team .cards-container {
    margin: 2rem auto 2rem auto;
}

.our-team .cards-container .card {
    width: 24%;
    /* margin-bottom: 7rem; */
}

.our-team .cards-container .card .img-div img {
    width: 100%;
    height: 200px;
    /* margin-top: -6rem; */
    transition: 1s ease;
}

.our-team .cards-container .card .img-div {
    background: #130855;
}

.our-team .cards-container .card h5 {
    color: #002a5b;
    text-align: center;
    font-family: Bahnschrift, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0.2rem;
}

.our-team .cards-container .card p {
    margin-bottom: 0.5rem;
    color: #555;
    font-family: Bahnschrift, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 112.5% */
}

/* .our-team .cards-container .card:hover .img-div img {
    margin-top: -11rem;
} */

.our-team .cards-container .card p.bold-para {
    color: #000;
    font-family: Bahnschrift, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.our-team .cards-container .card p.hover-show {
    /* display: none; */
    transition: 1s ease;
    height: 0;
    overflow: hidden;
}

.our-team .cards-container .card:hover p.hover-show {
    height: 5rem;
}

/* End Our Team------------------------ */

/* Start Events  */
.events  h2 {
    color: #000;
font-family: Bahnschrift, sans-serif;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: 42px; /* 105% */
}
.events  .location svg {
    fill: #555555;
}

.events .recent-posts .rp-card p.date,
.events .recent-posts .rp-card p.location {
    color: #555;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

/* End Events  */

/*-------------------------------------------------------
 | @medai ---------------------------- 
 ------------------------------------------------------------------*/


@media(max-width: 1199px) {


    .blogs-parent .card-content>img {
        min-width: 240px;
        max-width: 280px;
    }

    .blogs-parent .card-content h3 {
        font-size: 20px;
    }

    .blogs-parent .card-content p {
        font-size: 14px;
        margin-bottom: 0.3rem;
    }

    .blogs-parent .card-content h5 {
        font-size: 16px;
    }

    .blogs-parent .right .recent-posts .rp-card p.bold-para {
        font-size: 15px;
    }

    .our-team .cards-container .card .img-div img {
        width: 305px;
        height: 380px;
    }
}

@media(max-width:992px) {

    /* blogs  */
    .blogs-parent .card-content>img {
        min-width: 190px;
        max-width: 210px;
    }

    .media-press .mb-body .card a,
    .media-press .mb-body .card a h5 {
        font-size: 1.1rem;
    }

    .media-press .mb-body .card p {
        font-size: 14px;
        margin-bottom: 0.5rem;
    }

    .our-team .cards-container .card .img-div img {
        width: 258px;
        height: 330px;
    }

    .our-team .cards-container .card .img-div img {
        /* margin-top: -4rem; */
    }

    .our-team .top-content {
        margin: auto;
    }

    .our-team .cards-container .card:hover p.hover-show {
        height: 7rem;
    }

    .our-team .cards-container .card p.bold-para {
        font-size: 19px;
    }



}

@media(max-width:767px) {

    /* blogs  */
    .blogs-parent .blogs-body {
        flex-direction: column;
        gap: 1.5rem;

    }

    .blogs-parent .left,
    .blogs-parent .right {
        width: 100%;
    }


    .media-press .mb-body .card {
        width: 31%;
    }

    .our-team .cards-container .card .img-div img {
        width: 100%;
        height: 235px;
    }

    .our-team .cards-container .card h5 {
        font-size: 22px;
    }

    .our-team .cards-container .card .card-body {
        padding-top: 0;
    }

    .our-team .cards-container .card p.bold-para {
        font-size: 16px;
    }

    .our-team .cards-container .card:hover .img-div img {
        margin-top: -7rem;
    }

    .our-team .cards-container .card p {
        font-size: 14px;
    }

    .our-team .cards-container .card:hover p.hover-show {
        height: 6rem;
    }

    .our-team .cards-container {
        margin: 0rem auto auto auto;
    }
}


@media(max-width:630px) {
    .our-team .cards-container .card h5 {
        font-size: 20px;
    }

    .our-team .top-content h3 {
        font-size: 35px;
    }

    .our-team .top-content p {
        font-size: 17px;
        width: 100%;
    }

    .our-team .top-content {
        padding: 1rem;
    }

    .our-team .cards-container .card {
        width: 48%;
        /* margin-bottom: 6rem; */
    }
}

@media(max-width:575px) {

    /* blogs */
    .blogs-parent .card-content h3 {
        font-size: 17px;
    }

    .media-press .mb-body .card a,
    .media-press .mb-body .card a h5 {
        font-size: 1rem;
    }
    

}


@media(max-width:490px) {
    .blogs-parent .card-content {
        flex-direction: column;
    }

    .blogs-parent .card-content>img {
        max-width: 100%;

    }

    .media-press .mb-body .card {
        width: 45%;
    }

    .our-team .cards-container .card {
        width: 98%;
    }

    .our-team .cards-container .card .img-div img {
        height: 290px;
    }

    .our-team .top-content h3 {
        font-size: 30px;
    }

    .our-team .top-content p {
        font-size: 16px;
    }

}

@media(max-width:416px) {
    .media-press .mb-body .card {
        width: 95%;
    }

    .our-team .top-content h3 {
        font-size: 26px;
    }
}
.card-rc p {
    white-space: normal; /* Ensure content wraps naturally */
  }
  
  .read-more {
    display: inline !important; /* Force 'read more' to stay inline */
    margin-left: 5px; /* Add slight spacing after the text */
  }
  
  .read-more a {
    color: blue; /* Link color */
    text-decoration: none; /* Remove underline (optional) */
    font-weight: bold;
  }
  
  .card-rc p > * { 
    display: inline; /* Ensure all content within <p> stays inline */
  }
  
  .about-cont-block{
    border-top: 2px solid #002a5b;
  }