

.advertise-comp-2nd  {
    background: #EDF4FF;
}

.advertise-comp-2nd .right-content h3,
.advertise-comp-2nd .right-content p {
 color: #000;
}

/*Start  Section5  */

.advertise-comp-5th   {
    background-color: transparent;
}

.advertise-comp-5th .benefites  h4 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}

/*End Section5  */


/* Help  */
.advertise-help {
    background: #dfdfdf62;

}
/* .advertise-help .select-content {
    width: 73%;
} */
.advertise-help .select-content select {
    padding: 0.9rem 1rem;
    border: none;
    border: none;
    background-color: #fff;
    border-radius: 0.2rem;
    
}
