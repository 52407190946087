


/* Seller Details Component 2 ^^^^^^^^^^^^ */

/* .seller-detials-comp2 .company-logo-div .logo {
  margin-top: -35px;
} */
.seller-detials-comp2 .company-logo-div{
  width: 214px;
  height: 214px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #F1B31C;
  margin-top: -54px;
  border-radius: 7px ;
  background-color: #ffffff;
  padding: 10px;
}
.seller-detials-comp2 .company-logo-div img.logo {
  
  /* border-radius: 7px ; */
  overflow: hidden;
}

.seller-detials-comp2 .company-name h1 {
  color: #002a5b;
  font-size: 28px;
  font-weight: 700;
  line-height: 10px;
}

.seller-detials-comp2 .company-name h4 {
  color: #555;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
}

.seller-detials-comp2 .company-name p {
  color: #555;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  border-left: 2px solid;
}
.seller-detials-comp2 .company-name .company_categories{
  padding-bottom: 10px;
  border-bottom: 1px solid #f3f3f3;
}

.seller-detials-comp2 .company-name button {

  padding: 3px 6px;
  border-radius: 3px;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  background: #002a5b0d;
  margin-top: 0.5rem;

}
.company_contact{
  width: 100%;
  height: auto;
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.company_contact .call-now-button{
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
padding: 5px 10px;
border-radius: 3px;
background-color: #0383aa;
color: #fff;
text-decoration: none;

}
.company_contact .send-enquiry{
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 3px;
  background-color: #013668;
  color: #fff;
  text-decoration: none;
  }
  .company_contact .chat-now-button{
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #058a07;
    color: #fff;
    text-decoration: none;
    }

    .company_contact .live-chat-now-button{
      width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      border-radius: 3px;
      background-color: #f87800;
      color: #fff;
      text-decoration: none;
      }
      .company_contact .share-page{
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
   
        border-radius: 3px;
        background-color: #e2e2e1;
        color: #000000;
        text-decoration: none;
      }
.seller-detials-comp2 .contact-details a {
  padding: 1rem 1.5rem;
  border-radius: 0.3rem;
  border: 1px solid #002a5b;
  color: #002a5b;
  color: #002a5b;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
}

/* Start Seller detail compnent 3---------- */
.seller-details-comp3 .rounded-div {
  width: 116px;
  height: 88px;
  background: rgba(19, 81, 193, 0.12);
  border-radius: 0 0 2rem 2rem;
  margin-bottom: 2rem;
}

.seller-details-comp3 .left {
  background-color: #FAFAFA;
}

.seller-details-comp3 .left .fix-content {
  position: sticky;
  top: 3rem;
  left: 0;
}

.seller-details-comp3 .left-content .toggle-link {
  color: #555;
  font-size: 18px;
  font-weight: 500;
  padding: 1rem 1.2rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.active-build-ms-stab {
  background: #fff;
  color: #1754c2 !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.08);
}

/* Right ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */

/* .seller-details-comp3 .right {
  border: 1px solid #BCBCBC;
  border-radius: 0.5rem;
} */

.seller-details-comp3 .right h2 {
  color: #000;
  font-size: 24px;
  font-weight: 600;
}

.seller-details-comp3 .right h3 {
  color: #002a5b;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}


.seller-details-comp3 .right p {

  color: #555;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

/* .seller-details-comp3 .right .company-info,
.seller-details-comp3 .right .product-and-services,
.seller-details-comp3 .right .review-and-rating,
.seller-details-comp3 .right .request {
  border-bottom: 1px solid #BCBCBC;
} */

.star-golden {
  color: gold;
}

.star {
  color: gray;
}


/* 1st Right component  */
.seller-details-comp3 .company-info .companyinfo-cards .card {
  width: 180px;
  height: 103.746px;
  flex-shrink: 0;
  background: #002a5b;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.seller-details-comp3 .company-info .companyinfo-cards .card p {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 0.4rem;

}

.seller-details-comp3 .company-info .companyinfo-cards .card span {

  color: #FFAE00;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

}


/* Right our products -------------*/
.seller-details-comp3 .right .our-products button {
  color: #FFF;
  font-size: 10px;
  font-weight: 700;
  background-color: #002a5b;
  padding: 0.3rem 1.4rem;
  border-radius: 0.4rem;
}

.seller-details-comp3 .right .our-products .sc-cards .card{
  position: relative;
  width: 23.5%;

}
.seller-details-comp3 .right .our-products .sc-cards .card .badge
{
      width: fit-content;
      padding: 0.5rem 1.4rem;
      background: #002a5b;
      position: absolute;
      top: 0.1rem;
      left: 0.1rem;
}

.seller-details-comp3 .right .our-products h5 {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.seller-details-comp3 .right .our-products p {
  color: #555;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}

.seller-details-comp3 .right .our-products .prices span {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.18px;
}

/* review and rating ------------ */
.seller-details-comp3 .review-and-rating .total-rating>span {
  color: #FFF;
  font-size: 24px;
  font-weight: 600;
  background-color: #07B403;
  padding: 0.5rem;
  border-radius: 0.4rem;
}

.seller-details-comp3 .review-and-rating .total-rating h5 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

.seller-details-comp3 .review-and-rating .total-rating p {

  color: #555;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.blue-bg-button {
  padding: 0.7rem 1.3rem;
  /* width: 25%; */
  background-color: #002a5b;
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  border-radius: 0.4rem;
}


.seller-details-comp3 .review-and-rating .top-content button {
  padding: 0.8rem 1.5em;
  border-radius: 0.4rem;
  color: #FFF;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  background-color: #002a5b;
}

/* user review -------- */
.seller-details-comp3 .review-and-rating .user-reviews h6 {
  color: #525151;
  font-size: 16px;
  font-weight: 500;
}

.seller-details-comp3 .review-and-rating .user-reviews>div:not(:last-child) {
  border-bottom: 1px solid #dad4d4;
}

.seller-details-comp3 .review-and-rating button.load-reviews {
  padding: 0.7rem 2rem;
  color: #002a5b;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  border: 1px solid #002a5b;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
}

/* Request ------------- */


.seller-details-comp3 .right .request p.bold-para {
  color: #000000ab;
  font-size: 16px;
  font-weight: 500;
}

.seller-details-comp3 .right .request form input,
.seller-details-comp3 .right .request form select {
  color: #555;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding: 0.7rem;
  width: 48%;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
}

.seller-details-comp3 .right .request form input:focus-visible {
  outline: none;
}


.seller-details-comp3 .right .request form button.submit-btn {

  padding: 0.7rem;
  width: 25%;
  background-color: #002a5b;
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  border-radius: 0.4rem;
}


/* contat info --------------- */
.seller-details-comp3 .right .contact-info li i {
  text-align: center;
  display: inline-block;
  color: #8d8d8d;
  vertical-align: middle;
  font-size: 1.2rem;
}


.seller-details-comp3 .right .contact-info .cont-details>div {
  width: 100%;
}
.left-div .cont-details div h5{
  font-size: 16px !important;
}

.left-div .cont-details div p{
  font-size: 14px !important;
}
.seller-details-comp3 .right .contact-info .cont-details>div h5 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

.seller-details-comp3 .right .contact-info .cont-details>div p {

  color: #555;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.seller-details-comp3 .right .contact-info .right-div iframe {
  width: 100%;
  height: 300px;


}

/* Contact after login--------- */
.seller-details-comp3 .right .contact-info .before-log  a {
  color: #002a5b;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  width: 48%;
  padding: 0.95rem;
  text-align: center;
  border: 1px solid #002a5b;
  border-radius: 0.4rem;
  display: inline-block;
}
.seller-details-comp3 .right .contact-info .before-log  a.call-now {
  width: 92%;
  background-color: #002a5b;
  color: #fff;
}




.seller-details-comp3 .right .request form p.term-cond {
  font-size: 13px;
  line-height: 15px;
}




/* End Seller detail compnent 3----------- */


/* popup option */

.seller-details-comp3 #popup_option {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000080;
  z-index: 999;
  display: none;
}

.seller-details-comp3 .popup-content {
  max-width: 600px;
  margin: auto;
  border-radius: 0.4rem;
  margin-top: 20%;

}

.seller-details-comp3 .popup-content .tab-links button,
.seller-details-comp3 .popup-content .tab-links a {
  color: #2F9CEE;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  width: fit-content;
  padding: 0;
  /* 137.5% */
}


/* Start Suggest an Edit  */

.seller-details-comp3 .suggest-edit {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000080;
  z-index: 999;
  display: none;

}

.seller-details-comp3 .suggest-edit .suggest-form {
  max-width: 550px;
  margin: auto;
  margin-top: 4%;
  border-radius: 0.4rem;

}

.seller-details-comp3 .suggest-edit .suggest-form .content h2,
.seller-details-comp3 .popup-content h2 {
  color: #000;
  font-size: 28px;
  font-weight: 600;
  line-height: 22px;
}

.seller-details-comp3 .suggest-edit .suggest-form .content p,
.seller-details-comp3 .popup-content p {
  color: #555;
  font-size: 19px;
  font-weight: 500;
  line-height: 22px;
}

.seller-details-comp3 .suggest-edit .suggest-form .content span,
.seller-details-comp3 .popup-content span {
  font-size: 1.5rem;
  font-weight: 800;
  cursor: pointer;
}

.seller-details-comp3 .suggest-edit .suggest-form form {
  max-height: 480px;
  overflow-y: auto;
}

.seller-details-comp3 .suggest-edit .suggest-form form input,
.seller-details-comp3 .suggest-edit .suggest-form form select {
  padding: 0.6rem;
  width: 49%;
  border: 1px solid #bcbcbc;
  border-radius: 0.4rem;
  font-size: 1rem;
}

.seller-details-comp3 .suggest-edit .suggest-form form input:focus-visible,
.seller-details-comp3 .suggest-edit .suggest-form form textarea:focus-visible {
  outline: none;
}

.seller-details-comp3 .suggest-edit .suggest-form form .full-address input {
  width: 100%;
}


.seller-details-comp3 .suggest-edit .suggest-form form .upload-logo {
  border: 1px solid #bcbcbc;
  font-size: 1rem;
  color: #555;
  border-radius: 0.4rem;
  background: #fff;
}

.seller-details-comp3 .suggest-edit .suggest-form form .upload-logo label {
  left: 1rem;
  top: 0.7rem;
}

.seller-details-comp3 .suggest-edit .suggest-form form textarea {
  border-radius: 0.4rem;
  border: 1px solid #bcbcbc;
  padding: 0.7rem;

}

.seller-details-comp3 .suggest-edit .suggest-form form input[type="file"] {
  opacity: 0;
}

.seller-details-comp3 .suggest-edit form .form-btns button {
  color: #002a5b;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 49%;
  border: 1px solid #002a5b;
  border-radius: 0.3rem;
  padding: 0.8rem;
}

.seller-details-comp3 .suggest-edit form .form-btns button.submit-btn {
  color: #fff;
  background-color: #002a5b;
}


/* Edit Suggest an Edit  */





/* ^^^^^^^^^^^^^^^^ Media ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */

@media(max-width: 1399px) {
  .seller-details-comp3 .right .our-products .prices span {
    font-size: 16px;
  }
}


@media(max-width: 1199px) {
  .seller-detials-comp2 .company-name h1 {
    font-size: 32px;
  }

  .seller-detials-comp2 .contact-details {
    width: 24%;
  }

  .seller-details-comp3 .left-content .toggle-link {
    font-size: 16px;
  }

  .active-build-ms-stab {
    font-size: 17px !important;
  }

  .seller-details-comp3 .user-reviews>div h5 {
    font-size: 1.1rem;
  }

  .seller-details-comp3 .user-reviews>div .stars {

    font-size: 0.6rem;
    gap: 0.1rem !important;
  }

  .seller-details-comp3 .right p,
  .seller-details-comp3 .review-and-rating .total-rating p {
    font-size: 14px;
  }

  .seller-details-comp3 .review-and-rating .total-rating>span {
    font-size: 21px;
    padding: 0.4rem;
  }

  .seller-details-comp3 .review-and-rating .total-rating h5 {
    font-size: 18px;
    margin-bottom: 0.2rem;
  }

  .seller-details-comp3 .review-and-rating .top-content button {
    padding: 0.6rem 1.3rem;
    font-size: 16px;
    font-weight: 500;

  }

  /* products cars */
  .seller-details-comp3 .right .our-products .sc-cards .card {
    width: 31.5%;
  }

}


@media(max-width: 992px) {

  .seller-detail-banner > img {
    height: 330px !important;
  }
  .seller-detials-comp2>.container {
    flex-wrap: wrap;
  }

  .seller-detials-comp2 .company-name {
    width: 62%;
  }

  .seller-detials-comp2 .contact-details {
    flex-direction: row !important;
    width: 100%;
  }

  /* Seller detail comp3   */

  .seller-details-comp3 .left-content .toggle-link {
    font-size: 13px;
    padding: 0.8rem 0.6rem;
  }

  .active-build-ms-stab {
    font-size: 13px !important;
  }

  .seller-details-comp3 .rounded-div {
    width: 97px;
    height: 50px;
    margin-bottom: 1rem;
  }

  .seller-details-comp3 .review-and-rating .user-reviews>div {
    flex-wrap: wrap;
    gap: 0.4rem;

  }

  .seller-details-comp3 .right .request p.bold-para {
    font-size: 16px;
    margin-top: 0.5rem !important;
  }

  .seller-details-comp3 .right .contact-info {
    flex-wrap: wrap;
    gap: 1rem !important;
  }

  .seller-details-comp3 .right .contact-info .right-div iframe {
    width: 100%;
  }

  .seller-details-comp3 .right .request form button.submit-btn {
    width: 45%;
  }

  .seller-details-comp3 .right .contact-info .cont-details>div p {
    font-size: 14px;
  }

  .seller-details-comp3 .right .our-products .sc-cards .card {
    width: 48%
  }
  
  .seller-details-comp3 .right .company-info{
    padding: 1rem !important;
  }
  .seller-details-comp3 .right .company-info .companyinfo-cards > div{
    max-width: 549px;
  }

}



@media(max-width: 840px) {
  .company-info .companyinfo-cards > div {
    max-width: 510px !important;
}
}
@media(max-width: 767px) {

  .seller-detail-banner > img {
    height: 270px !important;
  }
  .seller-detials-comp2 .company-logo-div {
    width: 34%;
  }

  .seller-detials-comp2 .company-name,
  .seller-detials-comp2 .contact-details {
    padding-top: 0.5rem !important;
    border-bottom: 1px solid #f3f3f3;

  }

  .seller-detials-comp2 .company-name h1 {
    font-size: 24px;
    line-height: 28px;
  }

  .seller-details-comp3 .right h3 {
    font-style: 17px;
  }


  .seller-details-comp3 .right h2 {
    font-size: 22px;
  }

  .seller-details-comp3 .right .review-and-rating .top-content {
    flex-wrap: wrap;
  }

  .seller-details-comp3 .right .review-and-rating .user-reviews>div>div.col-lg-9 {
    flex-wrap: wrap;
  }

  .seller-details-comp3 .right>div {
    padding: 1rem !important;
  }

  .seller-details-comp3 .right .request form input,
  .seller-details-comp3 .right .request form select {
    width: 100%;
  }

  .seller-details-comp3 .right .request form>div:nth-child(2)>div,
  .seller-details-comp3 .right .request form>.three-inp>div {
    width: 100% !important;
  }


  .seller-details-comp3 .right .our-products .sc-cards .card .prices > div {
    gap: 6px ! important;
  }

  .company-info .companyinfo-cards > div {
    max-width: 430px !important;
}

}

@media(max-width: 575px) {

  .seller-detail-banner > img {
    height: 210px !important;
  }
  .seller-detials-comp2 .company-logo-div {
    width: 18%;
    height: 72px;
    justify-content: center;
  }
  .seller-detials-comp2 .company-name h1 {
    font-size: 20px;
    line-height: 28px;
}
.company_contact {
  width: 100%;
  height: auto;
  display: flex;
  gap: 10px;
  margin-top: 10px;
  overflow: auto;
}
.company_contact .call-now-button {
  min-width: 105px;
  max-width: 155px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 3px;
  background-color: #0383aa;
  color: #fff;
  text-decoration: none;
  white-space: nowrap; 
}
.company_contact .chat-now-button{
  min-width: 105px;
  max-width: 155px;
  height: 30px;
  white-space: nowrap; 
}

.company_contact .live-chat-now-button{
  min-width: 105px;
  max-width: 155px;
  height: 30px;
  white-space: nowrap; 
}
.company_contact .send-enquiry{
  min-width: 125px;
  max-width: 155px;
  height: 30px;
  white-space: nowrap; 
}
.company_contact .share-page{
  min-width: 30px;
  max-width: 155px;
  height: 30px;
  white-space: nowrap; 
}
  .seller-detials-comp2 .company-logo-div img:first-child {
    max-width: 150px !important;
  }

  .seller-detials-comp2 .company-name {
    width: 100%;
  }

  .seller-detials-comp2 .contact-details a {
    padding: 0.8rem 1.3rem;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
  }

  .seller-details-comp3 .container {
    flex-direction: column;
  }

}

@media(max-width:460px) {
  .seller-detail-banner > img {
    height: 170px !important;
  }
  .seller-detials-comp2 .contact-details a>span {
    padding-right: 0.2rem !important;
  }

  .seller-detials-comp2 .contact-details a {
    font-size: 14px;
    line-height: 14px;
    padding: 0.6rem 1rem;
  }

  .seller-detials-comp2 .contact-details {
    flex-wrap: wrap;
  }

  .seller-detials-comp2 .company-name button {
    font-size: 14px;
    line-height: 14px;
    padding: 0.6rem 1.2rem;
    margin-top: 0;
  }

  .seller-details-comp3 .suggest-edit .suggest-form form input,
  .seller-details-comp3 .suggest-edit .suggest-form form select {
    width: 100%;
  }
}



@media(max-width: 416px) {
  .seller-details-comp3 .right .contact-info .cont-details>div {
    width: 100%;
  }
}

@media(max-width: 360px) {
  .seller-details-comp3 .right .our-products .sc-cards .card {
    width: 100%
  }
}
.seller-detail-banner{
  background-color: #141414;
}
.seller-details-comp3{
  background-color: #f3f3f3;
  padding: 0px 0px 40px 0px;
}
.arrow-icon{
  font-weight: 300 !important;
}
.review-rating-section{
  width: 100%;
  height: 250px;
  border: 1px solid #4b4b4b2d;
  border-radius: 7px;
  display: flex;

  align-items: center;
  justify-content: center;
  justify-content: space-between;
}
.review-rating-left{
  width: 48%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

}
.review-rating-right{
  width: 48%;
  height: 100%;
  display: flex;
  flex-direction: column;
 
  justify-content: center;
  padding: 20px;
  
}
.review-rating-section1{
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
}
.review-rating-center{
  width: 1px;
  height: 240px;
background-color: #4b4b4b2d;
  
}
.review-get-section{
  width: 88%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto;
  margin-top: 40px;
 
}
.user-review-card{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #4b4b4b2d;

}
.user-review-details{
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  /* padding-left: 10px; */
  gap: 10px;
  padding-bottom: 20px;
  
}
.user-card-pic{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #918f8f;
  overflow: hidden;
  
}
.user-card-pic image{
  width: 100%;
  height: 100%;
}
.user-card-pic-details{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.user-reviews{
  border: 1px solid #4b4b4b2d;
  border-radius: 7px;
  padding: 20px;
  margin-top: 30px;
}
.contact-info{
  border: 1px solid #4b4b4b2d;
  border-radius: 7px;
  padding: 0px;
}