/*----------------------------------------------
 | Join Our Team & Job Description css
 -------------------------------------------------*/



/* Join Our Team banner */
.jot-banner .m-content {
    background-image: url("../../public/assets/images/join-our-team.png");
    height: 448px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;

}

.jot-banner .m-content button {
    position: absolute;
    top: 20%;
    left: 30%;
    position: absolute;
    top: 50%;
    /* left: 39%; */
    left: 50%;
    transform: translateX(-50%);
    border: 1px solidred;
    padding: 0.7rem;
    width: 20%;
    border-radius: 0.3rem;
    background-color: #1351C1;
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
}


/* Join Our Team Comp2 */
* {
    font-family: Bahnschrift, sans-serif;
    
}

.jot-com2 .main .left {
    width: 45%;
}

.jot-com2 .main .left>img {
    border-radius: 0 10rem 10rem 0;
    width: 100%;
}

.jot-com2 .main .right {
    width: 50%;
}

.jot-com2 .main .right p {
    color: #555;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.jot-com2 .main .right h3,
.jot-com2 .main .right p {
    width: 90%;
    margin-bottom: 1.5rem;
}

.jot-com2 .main .right button {
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
    padding: 0.6rem;
    border-radius: 0.2rem;
    background-color: #1351C1;
    width: 38%;
}


/* Form component */

.form-comp {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100pc;
    background: #00000040;
    z-index: 999;

}


.form-comp .form-div {
    width: 630px;
    margin: auto;
    border: 1px solid #E9ECEF;
    padding: 2rem 1.5rem;
    border-radius: 0.4rem;
    background-color: #fff;
    margin-top: 10rem;

}

/* .form-comp  {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: grey;
    top: 0;
    left: 0;
} */


.form-comp .form-div h6 {
    color: #000;
    font-size: 28px;
    font-weight: 600;
    line-height: 22px;
}

.form-comp .form-div>div button svg {
    height: 23px;
    width: 25px;

}

.form-comp .form-div p.bpara {
    color: #555;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin: 0.2rem auto 2rem auto;
}

.form-comp .form-div form input,
.form-comp .form-div form select {
    padding: 0.85rem;
    width: 100%;
    border-radius: 0.4rem;
    border: 1px solid #E9ECEF;
}

.form-comp .form-div form input {
    font-size: 16px;
}

.form-comp .form-div form input:focus-visible {
    outline: none;
}

.form-comp .form-div form p {
    font-size: 13px;
    color: red;
}

.form-comp .form-div form .dd-input>div {
    width: 49%;
}

.form-comp .form-div form .upload-div>div {
    border: 1px solid #E9ECEF;
    border-radius: 0.4rem;
}

.form-comp .form-div form .upload-div>div span {
    position: absolute;
    top: 1rem;
    left: 0.85rem;
    font-size: 16px;
    z-index: -1;
    line-height: 22px;
    color: #555;
}

.form-comp .form-div form .upload-div>div input {
    opacity: 0;
    z-index: 5;
    display: inline-block;
}

.form-comp .form-div form button {
    width: 48%;
    padding: 0.6rem;
    border: 1px solid #1351C1;
    border-radius: 0.2rem;
    color: #1351C1;
    font-size: 16px;
    font-weight: 600;


}

.form-comp .form-div form button.bg-lblue {
    background-color: #1351C1;
    color: #fff;
}


/* Current Opening------------- */

.curr-opening .main .cards .card {
    width: 23%;
    border-radius: 4px;
    background: #f8f8f8;
    padding: 0.7rem;
    /* box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.02); */
    border: none;

}

.curr-opening .main .cards .card .img-div span {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    top: 1rem;
    left: 1rem;
    background-color: red;
    border-radius: 0.3rem;
    padding: 0.55rem 0.8rem;
}

.curr-opening .main .cards .card>div p {
    color: #3D3D3D;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0.3rem;
}

.curr-opening .main .cards .card p.big-para {
    color: #555 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.curr-opening .main .cards .card button {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    border-radius: 0.3rem;
    padding: 0.48rem;
    border: 1px solid #002a5b;
    background-color: #fff;
    color: #002a5b;
    transition: 0.4s ease;
    width: 100%;
}

.curr-opening .main .cards .card button:hover {
    background-color: #002a5b;
    
    color: #fff;


}

/* ------------------------------------------
 |Job Description
 ------------------------------------------*/
.job-desc .main-cont .left {
    width: 68%;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    border: 1px solid #dee2e6a3;
}

.job-desc h4 {
    color: #002a5b;
    font-size: 22px;
    font-weight: 600;
}

.job-desc .left .ldiv p.bpara {
    color: #555;
    font-size: 16px;
    font-weight: 600;
}

.job-desc .left .ldiv p.bpara span {
    text-transform: uppercase;
    font-weight: 400;
}

.job-desc .main-cont .left>img {
    width: 100%;

}

.job-desc .main-cont .left .ldiv {
    width: 100%;
    padding-right: 1rem;
}

.job-desc .main-cont .left .ldiv svg {
    width: 16px;
    height: 16px;
    fill: #1351C1;
}

.job-desc .main-cont .left .ldiv .art-2>div,
.job-desc .main-cont .left .ldiv .art-3>div {
    padding: 0.2rem 0;
}

.job-desc .main-cont .left .rdiv button {
    color: #EEE;
    font-size: 16px;
    font-weight: 600;
    padding: 0.6rem;
    width: 100%;
    background-color: #1351C1;
    border-radius: 0.2rem;
    margin-top: 0.6rem;
}


/* Right  */
.job-desc {
    background-color: #ffffff;
    border-top: 2px solid #002a5b;
}

.job-desc .main-cont .right {
    width: 30%;
    border-radius: 0.5rem;
    border: 1px solid #dee2e6a3;
}

.job-desc .main-cont .right .search form input {
    width: 100%;
    padding: 0.55rem;
    font-size: 16px;
    border-radius: 0.4rem;
    border: 1px solid #E2E2E2;
    background-color: transparent;
}

.job-desc .main-cont .right .search form input:focus-visible {
    outline: none;
}

.job-desc .main-cont .right .search form span {
    font-size: 22px;
    position: absolute;
    right: 1rem;
    top: 0.4rem;
}

.job-desc .main-cont .right .rp-card img {
    width: 60px;
    height: 64px;
}

.job-desc .main-cont .right .rp-card:not(:last-child) {

    border-bottom: 1px solid #E2E2E2;
}

.job-desc .main-cont .right .rp-card p {
    color: #555;
    font-size: 14px;
    font-weight: 500;
}

.job-desc .main-cont .right .rp-card svg {
    width: 16px;
    height: 16px;
}

.job-desc .main-cont .right .rp-card p.bold-para {

    color: #000;
    font-family: Bahnschrift, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}



/* -----------------------------------------------
|@media Query --------------------
 ----------------------------------------------*/

@media(max-width:1499px) {
    .jot-banner .m-content button {
        position: relative;
        top: 38%;
    }
}


@media(max-width: 1199px) {
    .jot-banner .m-content button {
        width: 28%;
    }
}

@media(max-width: 1080px) {
    .curr-opening .main .cards .card {
        width: 31%;
    }
    .jot-com2 .main .right button {
        font-weight: 600;
        font-size: 21px;
    }
}

@media(max-width: 992px) {
    .job-desc h4 {
        font-size: 20px;
    }
    .jot-com2 .main .right p {
        font-size: 21px;
    }
    .job-desc .main-cont .right {
        width: 28%;
    }

    .job-desc .main-cont {
        flex-direction: column;
        gap: 1.5rem;
    }

    .job-desc .main-cont .left,
    .job-desc .main-cont .right {
        width: 100%;
    }

    p {
        font-size: 15px;
    }
    .jot-com2 .main .left {
        width: 48%;
    }
}

@media(max-width: 767px) {
    .jot-banner .m-content button {
        width: 38%;
    }
    .jot-banner .m-content button {
        top: 50%;
    }
    .curr-opening .main .cards .card {
        width: 48%;
    }

    .jot-com2  .main {
        flex-direction: column;
        gap: 1rem;
    }
    .jot-com2 .main .left, 
    .jot-com2 .main .right {
        width: 100%;
        padding: 0.5rem 1.3rem ;
    }
    .jot-com2 .main .left>img {
        border-radius: 0.4rem;
    }
    .jot-com2 .main .right h3{
        margin-bottom: 1rem;
    }
   
}


@media(max-width: 575px) {
    .jot-banner .m-content button {
            width: 53%;
    }
    .job-desc .left>div {
        flex-direction: column;
        gap: 1rem;
    }

    .job-desc .main-cont .left .ldiv,

    .job-desc .main-cont .left .rdiv {
        width: 100%;
    }

    .job-desc .main-cont .left .ldiv {
        padding-right: 0;
    }

    .job-desc .main-cont .left .rdiv button {
        width: 42%;
    }
    .jot-com2 .main .right p {
    font-size: 19px;
    width: 100%;
    margin-bottom: 1rem;
    }

}

@media(max-width: 490px) {
    .job-desc .main-cont .left {
        padding: 0.6rem;
    }
   

}


@media(max-width: 460px) {
    .curr-opening .main .cards .card {
        width: 100%;
    }
    .jot-banner .m-content button {
        top: 43%;
        font-weight: 500;
        font-size: 1.1rem;
        width: 60%;
    }
    .jot-com2 .main .right button {
        width: 48%;
        padding: 0.4rem;
        font-weight: 500;
        
    }
}


@media(max-width: 416px) {
    .job-desc .main-cont .left .rdiv button {
        width: 100%;
    }
    .jot-banner .m-content button {
        width: 70%;
    }
}