/* Start Startup Component3 */
.startup-detail-comp3 .company-info .cards .card p {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
    margin: 0px;
}

.startup-detail-comp3 .company-info .cards .card span {
    color: #FFAE00;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    margin: 0px;
}

.startup-detail-comp3 .company-info .cards .card {
    width: 23.5%;
    background: #1351C1;
    border-radius: 0.5rem;
}

/* End Startup Component3 */


.rate-star {
    color: grey;
    font-size: 1.5rem;
    padding: 0.4rem 0.6rem;
    border: 1px solid #555;
    border-radius: 0.4rem;
    margin-right: 0.4rem;
    cursor: pointer;
}

.rate-star-golden {
    color: #FFAE00;
    font-size: 1.5rem;
    padding: 0.4rem 0.6rem;
    border: 1px solid #555;
    border-radius: 0.4rem;
    margin-right: 0.4rem;
    cursor: pointer;
}

.startup-detail-comp3 .pitch-desk  > button {
    padding: 0.7rem 1.3rem;
    /* width: 25%; */
    background-color: #1351C1;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    border-radius: 0.4rem;
}





/*^^^^^^^^^^^^^^^ Media ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */

@media(max-width: 1199px) {
.startup-detail-comp3 .company-info .cards .card p {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 0.4rem;
}
.startup-detail-comp3 .company-info .cards .card span {
    font-size: 32px;
}
}


@media(max-width: 992px) {
    .startup-detail-comp3 .company-info .cards .card {
        width: 48%;
    }
}
