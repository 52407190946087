/* Start Banner */

.startup-banner {
    max-width: 99%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.startup-banner .carousel .slide img,
.startup-banner .carousel .slider li {
    border-radius: 0.3rem;
}


/* End Banner */



/* Start category */
.startup-category .cat-cards .logo-div {
    width: 154.346px;
    height: 140.966px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: #D4E3FF;
    margin: auto;
}

.startup-category .cat-cards  .card{
    background: white;
}


.startup-category .cat-cards h5 {
    color: #555;
    font-family: Bahnschrift, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
    text-align: center;
}

.startup-category .cat-cards .owl-nav.disabled {
    display: block;
    position: absolute;
    right: 1rem;
    top: -3.5rem;
}

.startup-category .cat-cards .owl-nav.disabled> button {
    padding: 0rem 0.4rem !important;
    background:  #1351C1;
    color: #fff;
}



/* Start Startup Container   */

.search-filter {
    min-width: 1080px;
}
.startup-container .left input {
    width: 70%;
    border-radius: 4px;
    border: 1px solid #f3f3f3;
    background: #fff;
}
.search-icon{
    width: 40px;
    height: 35px;
    /* border: 1px solid #f3f3f3; */
    background: #fff;
    border-radius: 3px;
    margin-left: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.startup-container .left select {
    width: fit-content;
    padding-left: 0;
}

.startup-container .search-filter {
    background-color: #f5f5f5;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}


/* Starup Details   */

.startup-details .right-content h3 {
    color: #000;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}

.startup-details .right-content .title-cat button {
    color: #444;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    border-radius: 4px;
    background: #e5e5e5;
    padding: 0.3rem 0.8rem;
}

.startup-details .right-content .title-btns a,
.startup-details .right-content .right-bottom a {
    width: 167px;
    height: 45px;
    border-radius: 4px;
    background: #1351c1;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.startup-details .right-content .right-bottom .claim-listing {
    background: #ffae00;
    color: #000;
}

.startup-details .right-content .right-bottom h5 {
    color: #9c9c9c;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}
.establish{
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    /* background-color: rgb(175, 175, 255); */
    text-align: center;
    border-radius: 3px;
}
.valuation{
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    /* background-color: rgb(139, 185, 199); */
    text-align: center;
    border-radius: 3px;
}
.revenue{
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    /* background-color: rgb(238, 131, 241); */
    text-align: center;
    border-radius: 3px;
}
.net-profit{
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    /* background-color: rgb(248, 123, 123); */
    text-align: center;
    border-radius: 3px;
}
.visitor{
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding: 10px;
    /* background-color: rgb(248, 123, 123); */
    text-align: start;
    border-radius: 3px;
}
.startup-details .right-content p {
    color: #555;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
}

.startup-details .right-content .right-bottom p {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
}

.seprator{
    width: 2px;
    height: 40px;
    background-color: #ccc;
}

/* End Starup container 


/* Start Subscribe  */

.subscribe .bg-img {
    background: url("../../public/assets/images/smart-city.jpg");
    background-repeat: round;
    background-size: cover;
    height: 380px;
}

.subscribe .bg-img .bg-color {
    left: 0rem;
    background: #0000004a;
    z-index: 1;
}

.subscribe .subscribe-content {
    width: fit-content;
    margin: auto;
    z-index: 9;
}

.subscribe .subscribe-content h2 {
    color: #fff;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.subscribe .subscribe-content p {
    color: #fff;
    font-size: 23px;
    line-height: 32px;
    text-align: center;
}

.subscribe .subscribe-content .get-notify {
    background: #fff;
    padding-left: 1rem;
    margin: auto;
    width: 75%;
}

.subscribe .subscribe-content .get-notify input:focus-visible {
    outline: none;
}

.subscribe .subscribe-content .get-notify button {
    background: #ffae00;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    border-radius: 0;
    padding: 0.6rem 1rem;
}

.subscribe .paras p {
    color: #555;
    font-size: 18px;
    line-height: normal;
}


/* End Subscribe  */


/* Start Registor MarketPlace   */

.registor-marketplace .top-content h6 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.registor-marketplace .top-content h3 {
    color: #1351c1;
    font-size: 36px;
    font-weight: 600;
    line-height: 36px;
}

.registor-marketplace .top-content p {
    color: #555;
    font-size: 16px;
    line-height: 24px;
}


/* cards  */

.registor-marketplace .cards>div {
    height: 347px;
}

.registor-marketplace .cards>div:first-child {
    background: #FFAE00;
}

.registor-marketplace .cards>div:last-child {
    background: #1351C1;
}

.registor-marketplace .cards a {
    background: #1351C1;
    padding: 0.5rem 1.6rem;
    border-radius: 0.4rem;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}

.registor-marketplace .cards h5 {
    color: #FFF;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: 34px;
    width: 70%;
    margin: 1rem 0;
}

.registor-marketplace .cards a.bg-yellow {
    background: #FFAE00;
}


/* End Registor MarketPlace   */


/* Start Open Start page */

.open-startup .left-contnet .links a {
    background: #1351c1;
    border-radius: 0.2rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 1rem;
    padding: 0.6rem 2rem;
    width: 60%;
}


/* End Open Start page */


/* Start Startup Form ----------- */

.startup-form .form-content form {
    gap: 1rem;
}
.startup-form  form input, 
.startup-form  form select 
 {
    border: 1px solid white;
    box-shadow: 0 0 3px lightblue;
}
.startup-form .form-content input,
.startup-form form .bottom-inputs>div {
    width: 30%;
    padding: 0.9rem;
    border-radius: 0.3rem;
    border: 1px solid #e5e5e5;
    background-color: #fff;
}

.startup-form .form-content input[type="checkbox"] {
    width: fit-content;
}

.startup-form .form-content .k-editor {
    /* height: 199px; */
}

.startup-form .form-content input.company-input,
.startup-form .form-content input.address-input {
    width: 60.7%;
}

.startup-form form .bottom-inputs>div input {
    opacity: 0;
    z-index: 5;
    padding: 0;
}

.startup-form form .bottom-inputs>div label {
    top: 1rem;
    left: 1rem;
    color: #767575;
}

.startup-form form button {
    background: #1351c1;
    border-radius: 0.2rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 1rem;
    padding: 0.6rem 5rem;
}


/* End Startup Form ----------- */


/*--------------------------------
| Medai Query 
---------------------------------------- */

@media (max-width: 1199px) {

    /* Startup Container  */
    .startup-container .left {
        width: 87%;
    }

    .startup-details .right-content .title-btns a,
    .startup-details .right-content .right-bottom a {
        width: 120px;
        height: 43px;
    }

    .startup-details .right-content h3 {
        font-size: 23px;
    }

    .startup-details .right-content .title-cat button {
        font-size: 16px;
        font-weight: 600;
        padding: 0.3rem 0.5rem;
    }

    .open-startup .left-contnet .links a {
        width: 80%;
    }
}

@media (max-width: 992px) {
    .startup-banner {
        gap: 1.6rem;
    }

    .startup-banner>div:last-child {
        margin: 0;
    }

    /* Startup Container  */
    .startup-details .right-content .title-btns {
        flex-wrap: wrap-reverse;
        gap: 1rem;
    }

    /* Subscribe  */
    .subscribe .bg-img {
        height: 300px;
    }

    .subscribe .subscribe-content h2 {
        font-size: 30px;
    }

    .subscribe .subscribe-content p {
        font-size: 19px;
    }

    /* Register  */
    .registor-marketplace .cards h5 {
        width: 100%;
        font-size: 24px;
        line-height: 29px;
    }

    /* startup form   */
    .startup-form .form-content .k-editor {
        height: 300px;
    }
}

@media (max-width: 767px) {

    /* Statup Details  */
    .startup-details .company-content {
        flex-direction: column;
        text-align: center;
    }

    .startup-details .right-content .title-btns>div:last-child {
        width: 100%;
        justify-content: space-between;
    }
/* 
    .startup-container .search-filter {
        flex-direction: column;
    } */

    .startup-container .left {
        width: 100%;
    }

    /* .subscribe  */
    .subscribe .subscribe-content .get-notify {
        width: 90%;
    }

    /* startup form  */
    .startup-form .form-content input,
    .startup-form form .bottom-inputs>div {
        width: 48%;
    }

    .startup-form .form-content input.company-input,
    .startup-form .form-content input.address-input {
        width: 97.6%;
    }

    .startup-form .form-content .bottom-inputs {
        margin-bottom: 3rem;
    }
}

@media (max-width: 460px) {
    .startup-banner {
        gap: 0.7rem;
    }

    /* Subscribe   */
    .subscribe .subscribe-content .get-notify button {
        font-size: 14px;
    }

    .subscribe .subscribe-content .get-notify {
        width: 99%;
    }

    .startup-form .form-content .k-editor {
        height: 350px;
    }

    .startup-form .form-content input,
    .startup-form .form-content input,
    .startup-form form .bottom-inputs>div {
        width: 98%;
    }
}

@media(max-width: 416px) {
    .open-startup .left-contnet .links a {
        font-size: 0.8rem;
        width: 97%;
    }
}
.company-content{
    border:1px solid #d1d1d1;
}
.startup-company-name{
    width: 100% !important;
    background: none !important;
    text-align: start !important;
    justify-content: start !important;


}
.right-content .title-btns .title-cat .startup-company-name h3:hover{
    color: #002a5b !important;
    text-decoration: underline !important;
}