

/* Start Post Compoentne 2nd  */

.post-buy-comp-2nd .right-form form
 {
    /* width: 85%; */
    margin-top: 1.4rem;
}
.post-buy-comp-2nd .right-form .links{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}
.topratedform{
    width: 100%;
    height: auto;
    padding: 10px;
    border: 1px solid rgba(128, 128, 128, 0.144);
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    border-radius: 4px;
}
.city-data{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.post-buy-comp-2nd .right-form .city-data select{
    color: #555;
width: 32%;
font-size: 14px;
}
.post-buy-comp-2nd .right-form .links{
    margin-top: 1.4rem;
}
.post-buy-comp-2nd .right-form h4 {
    font-size: 1.75rem;
    font-weight: 600;
    color: #003975;
}
.post-buy-comp-2nd .right-form input,  
.post-buy-comp-2nd .right-form select {
    background-color: #fff;
    color: #555;
    font-size: 14px;
    border: 1px solid rgba(128, 128, 128, 0.144);
    border-radius: .2rem;
    padding: .9rem 1rem;
    width: 49%;
}

.form-floating textarea{
    background-color: #fff;
    color: #555;
    border: 1px solid rgba(128, 128, 128, 0.144);
    border-radius: .2rem;
    padding: .9rem 1rem;
}
.form-heading-top p{
    font-size: 18px;
    font-weight: 600;
    color: black;
}
.post-buy-comp-2nd .right-form input[type = "email"] {
    width: 100%;
    font-size: 14px;
}

.post-buy-comp-2nd .right-form input[type = "checkbox"] {
    width: fit-content;
}

.post-buy-comp-2nd .right-form form p.accept-terms {
    font-size: 14px;
    color: black;
    line-height: 20px;
    font-weight: 400;
}

.post-buy-comp-2nd .right-form button {
    width: 49%;
background: #1351c1;
border-radius: .2rem;
color: #fff;
font-size: 1.2rem;
font-weight: 500;
/* margin-top: 1rem; */
padding: .6rem ;
/* width: 48%; */
}
.post-buy-comp-2nd .right-form .submit-button {
    width: 100% !important;
background: #1351c1;
border-radius: .2rem;
color: #fff;
font-size: 1.2rem;
font-weight: 500;
/* margin-top: 1rem; */
padding: .6rem ;
/* width: 48%; */
}
.imp-text{
    color: red;
    font-size: 13px;
}
/* End Post buy Compoentne 2nd  */


/* Start Post buy Component 3rd   */
.post-buy-comp-3rd .top-contnet {
    margin: auto;
    
}
.post-buy-comp-3rd .top-seller {
    overflow-x: auto;
}
.post-buy-comp-3rd .cards {
    min-width: 800px;
}

/* End Post buy Component 3rd   */


/* Media Query  */

@media(max-width:992px) {
    .post-buy-comp-3rd .top-contnet {
        width:  100% !important;
    }
}

@media(max-width: 767px) {
    .post-buy-comp-2nd .right-form form {
        width: 100%;
    }
}


@media(max-width: 575px) {
    .post-buy-comp-2nd .right-form .links button {
        padding: 0.5rem 3rem;
    }
    .seller-banner h2 {
    text-align: center;
    }
}


@media(max-width: 416px) {
    .post-buy-comp-2nd .right-form form input,
    .post-buy-comp-2nd .right-form form select {
        width: 100%;
    }

    .post-buy-comp-2nd .right-form .links button,
    .post-buy-comp-2nd .right-form button  {
        padding: 0.3em 2rem;
    }


    .post-buy-comp-2nd .right-form form > div {
        flex-direction: column;
    }

    .post-buy-comp-2nd .right-form .links button {
        padding: 0.5rem 2rem;
    }
    
}