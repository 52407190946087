.floating-alert {
  display: none;
  position: absolute;
  z-index: 999;
  top: 38px;
  left: 50%;
  transform: translateX(-50%);
  -moz-animation: floatingAlert ease-in 5s forwards;
  -webkit-animation: floatingAlert ease-in 5s forwards;
  -o-animation: floatingAlert ease-in 5s forwards;
  animation: floatingAlert ease-in 5s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.floating-alerts .floating-alert:last-of-type {
  display: block;
}

@keyframes floatingAlert {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) scale(1.2);
  }

  9% {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) scale(1);
  }

  91% {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) scale(1);
  }

  100% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) scale(1.2);
  }
}

@-webkit-keyframes floatingAlert {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) scale(1.2);
  }

  9% {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) scale(1);
  }

  91% {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) scale(1);
  }

  100% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) scale(1.2);
  }
}
