.report-abuse .left h6 {
  color: #555;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 1.4rem 0 0.5rem;
}

.report-abuse p {
  color: #555;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

/* Right  */
.report-abuse .right-form h4 {
  /* font-size: 28px; */
  line-height: 34px;
  margin-bottom: 2rem;
  color: #003975;
  font-size: 1.75rem;
  font-weight: 600;
  
}

.report-abuse .right-form input,
.report-abuse .right-form select {
  background-color: #f7f7f7;
  border: none;
  border-radius: 0.2rem;
  padding: 0.9rem 1rem;
  width: 49%;
  color: #555;
  font-size: 14px;
}

.report-abuse .right-form input:focus-visible,
.report-abuse .right-form select:focus-visible,
.report-abuse .right-form textarea:focus-visible
{
    outline: none;
}

.report-abuse .right-form form textarea {
  background: #f7f7f7;
  border-radius: 0.2rem;
}

.report-abuse .right-form .lform-contnet h5 {
  color: #555;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.report-abuse .right-form .rform-content input[type="checkbox"] {
  width: fit-content;
}

.report-abuse .right-form button {
  width: 100%;
  background: #1351c1;
  border-radius: 0.2rem;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 1rem;
  padding: 0.6rem 5rem;
}


.report-abuse .right-form form p.small-para {
    font-size: 13px;
}


/* Start Media Query */

@media(max-width: 992px) {
    .report-abuse .container {
        flex-direction: column;
        gap: 2rem;
    }
}



@media(max-width: 490px) {
    .report-abuse h3 {
        font-size: 33px;
    }

    .report-abuse p {
        font-size: 14px;
    }
    .report-abuse .left h6 {
        font-size: 15px;
        font-weight: 600;
    }
}


@media(max-width: 416px) {
    .report-abuse .right-form form> div {
        flex-direction: column ;
        /* gap: 8px !important; */
    }

    .report-abuse .right-form input, .report-abuse .right-form select {
        width: 100%;
    }
}
/* End Media Query */