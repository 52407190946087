/* .main-banner-bg {
    background-image: url(../../../public/assets/images/home-banner.png);
    height: 100%;
    width: 100%;
    padding: 50px 31px 30px 30px;
} */

/* .products-banner {
    display: flex;
    gap: 20px;
    list-style-type: none;
    color: #FFF;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    justify-content: center;
    padding-left: 0rem;
}

.products-banner a {
    color: #fff;
} */

/* .second-section-cols .accordion-header button {
  padding: 7px;
  font-size: 16px;
  font-weight: 600;
  color: #666666;
} */

.second-section-cols .accordion-body {
  padding: 5px;
}

.banner-services {
  color: #fff;
  font-family: Bahnschrift, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  list-style-type: none;
  display: flex;
  padding-left: 0rem;
  justify-content: center;
}

.banner-services a {
  color: #fff;
}

/* .apna-pro-bg h6 {
    color: #000;
    font-family: Bahnschrift, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 15px;
} */

.apna-pro-bg h5 {
  color: #000;
  font-family: Bahnschrift, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.services-category p {
  color: #000;
  font-family: Bahnschrift, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.5rem;
}

.services-category-bg {
  border-radius: 10px;
  background: #fff;
  /* box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.1); */
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 15px;
}

.apna-pro-bg a {
  color: #1351c1;
  font-family: Bahnschrift, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline !important;
}

/* .apna-pro-pera {
    display: none;
} */

#hello:checked+.apna-pro-pera {
  display: block;
}

.apna-pro-bg:hover .apna-pro-pera {
  display: block;
  /* Show the <p> tag when hovering over .apna-pro-bg */
}

/* 
.empower-bussiness-content {
    width: 80%;
    margin: auto;
    text-align: center;
    margin-top: 30px;
} 

 .empower-bussiness-content h1 {
    color: #000;
    text-align: center;
    font-family: Bahnschrift, sans-serif;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.empower-bussiness-content p {
    color: #555;
    text-align: center;
    font-family: Bahnschrift, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
} */

.buss-stepper .MuiStepLabel-label {
  color: #000;
  font-family: Bahnschrift, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 20px;
}

.buss-stepper .Mui-active {
  font-size: 28px;
}

/* .form-section-apna {
    width: 30%;
    border-radius: 9px;
    border: 1px solid #CCC;
    background: #1351C1;
    padding: 10px;
} */

/* .content-section-apna {
    width: 60%;
} */

/* .form-section-apna h6 {
    color: #FFF;
    font-family: Bahnschrift, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.form-section-apna p {
    color: #FFF;
    font-family: Bahnschrift, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
}

.form-section-apna button {
    border-radius: 4px;
    background: #FFB800;
    color: #FFF;
    font-family: Bahnschrift, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    margin-top: 10px;
    padding: 7px;
}

.form-section-apna input {
    border-radius: 4px !important;
    border: 1px solid #B9B9B9;
    background: #FFF;
    color: #908F8F !important;
    font-family: Bahnschrift, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 15px;
}

.form-section-apna select {
    border-radius: 4px !important;
    border: 1px solid #B9B9B9;
    background: #FFF;
    color: #908F8F;
    font-family: Bahnschrift, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 15px;
    padding: 10px;
} */

/* .our-comp {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.our-comp-1 {
    width: 45%;
} */

/* .apna-user {
    border-radius: 9px;
    border: 1px solid #CFCFCF;
    background: #FFF;
}

.apna-user h3 {
    color: #000;
    text-align: center;
    font-family: Bahnschrift, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px;
} */

/* .apna-investors-section h6 {
    color: #000;
    font-family: Bahnschrift, sans-serif;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin-top: 20px;
}

.apna-investors-section p {
    color: #555;
    font-family: Bahnschrift, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: justify;
}

.apna-investors-section h2 {
    color: #555;
    font-family: Italiana;
    font-size: 66px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
} */

.user-details-1 {
  display: flex;
  gap: 10px;
  align-items: start;
}

.user-details-1 h4 {
  color: #000;
  font-family: Bahnschrift, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.user-details-1 h5 {
  color: #555;
  font-family: Bahnschrift, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.user-details-1 h3 {
  color: #555;
  font-family: Bahnschrift, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* .apna-investors {
    display: grid;
    grid-template-columns: 11% 11% 11% 11% 11% 11% 11% 11%;
    gap: 17px;
} */

.apna-user p {
  color: #555;
  text-align: center;
  font-family: Bahnschrift, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* .our-comp-bg {
    background: rgba(19, 81, 193, 0.07);
    padding: 40px;
    margin-top: 30px;
}

.our-comp h1 {
    color: #000;
    font-family: Bahnschrift, sans-serif;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.our-comp p {
    color: #555;
    font-family: Bahnschrift, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.our-comp button {
    border-radius: 29px;
    background: #1351C1;
    color: #FFF;
    font-family: Bahnschrift, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 8px 25px;
} */

/* .help-points {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-top: 15px;
} */

/* .content-section-apna p {
    color: #555;
    font-family: Bahnschrift, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0.3rem;
} */

/* .content-section-apna h3 {
    color: #555;
    font-family: Bahnschrift, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.content-section-apna h5 {
    color: #555;
    font-family: Bahnschrift, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
} */

.banner-services li {
  padding-right: 5px;
}

/* .banner-bottom-part {
    width: 80%;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    margin: auto;
    position: relative;
    top: -20px;
} */

/* .banner-bottom-part h6 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.banner-bottom-part p {
    color: #908F8F;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
} */

/* .request-quotes {
    display: flex;
    width: 33%;
    gap: 15px;
    align-items: center;
    border-right: 1px solid;
    justify-content: center;
    padding: 20px 0px;
}

.request-quotes:nth-child(3) {
    border: none;
} */

.second-section-bg {
  border-radius: 10px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  height: 450px;
}

.second-section-cols {
  display: grid;
  grid-template-columns: 24% 50% 25%;
  padding: 20px 10px;
}
.second-section-cols .left-content .dropdown-toggle::after{
 display: none;
}

.second-section-cols .accordion {
  border: none;
}

.second-section-cols .accordion-item {
  border: none;
}

/* .second-section-cols .left-content .accordion-header > button {
  height: 59px;
} */

.second-section-cols .dropdown>button {
  border-bottom: 1px solid #eeeeee !important;
  height: 59px;
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 600;
  padding: 7px;
  background-color: #f7f7f77a;
  /* margin-bottom: 15px; */
  /* border-radius: 3px; */
}

.second-section-cols .dropdown .dropdown-menu a {
  color: #555 !important;
  font-family: Bahnschrift, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  justify-content: space-between;
  line-height: 16px;
  padding: 5px !important;
  height: fit-content;
  display: block;
  transition: 0.4s ease;
}

.second-section-cols .dropdown .dropdown-menu a:hover {
  background: #eee;
  font-weight: 600;
}

.second-section-cols .dropdown-toggle::after {
  position: absolute;
  right: 1rem;
  top: 1.6rem;
}

/* .second-section-cols .accordion .accordion-header button {
  border: none;
  border-bottom: 1px solid #eeeeee;
} */
.second-section-cols h6 {
  color: #000;
  font-family: Bahnschrift, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* 
.second-section-cols .hr-slider {
    width: 665px;
    height: 400px;
} */

/* .second-section-cols .hr-slider .slick-list {
    height: 400px;
} */
.get-quotes-section {
  background-image: url("../../../public/assets//images/get-quotes-bg1.png");
  padding: 25px 0px;
  text-align: center;
  background-size: cover;
  width: 100%;
  height: 145px;
  background-repeat: no-repeat;
  background-origin: border-box;
}

.sell-on-apnashaher-btn {
  border-radius: 10px;
  border: 1px solid rgba(255, 184, 0, 0.16);
  background: rgba(255, 184, 0, 0.44);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  width: 80%;
  padding: 8px;
}

.get-quotes-btn {
  border-radius: 10px;
  border: 1px solid rgba(19, 81, 193, 0.87);
  background: rgba(19, 81, 193, 0.16);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  width: 80%;
  padding: 8px;
  margin-bottom: 15px;
}

.business-promotion {
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 10px;
  background: #f7f7f77a;
  padding: 7px;
  height: 59px;
}

.business-promotion p {
  color: #1e1e1e;
  font-family: Bahnschrift, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 0rem;
  width: 80%;
}

.second-section-cols h4 {
  color: #000;
  font-family: Bahnschrift, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  margin-top: 13px;
}

.transparent-btn {
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  width: 100%;
  padding: 5px;
}

.blue-btn {
  border-radius: 10px;
  border: 1px solid #1351c1;
  background: #1351c1;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  width: 100%;
  padding: 5px;
}

.third-section {
  display: grid;
  grid-template-columns: 60% 40%;
  gap: 20px;
  margin-top: 70px;
  margin-bottom: 40px;
}
.third-section1{
  grid-template-columns: 60% 18% !important;
  margin-top: 0px !important;
}

/* .counter-part {
    border-left: 1px solid grey;
    padding: 0.4rem 0.2rem ;
} */
 .explore-millions{
  display: flex;
  /* align-items: center; */
 }
 .explore-millions h2{
  font-weight: 700;
 }
.counter-part span {
  color: #feab01;
  font-family: Bahnschrift, sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
}

.counter-part p {
  color: #ffffff;
  font-family: Bahnschrift, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0px !important;
}

/* .counter-part {
    width: 40%;
} */

/* .counter-part1 span {
    color: #1351C1;
    font-family: Bahnschrift, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
}

.counter-part1 p {
    color: #000;
    font-family: Bahnschrift, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px
} */

/* .counter-part1 {
    width: 45%;
} */

.third-section h2 {
  color: #ffffff;
  font-family: Bahnschrift, sans-serif;
  font-size: 41px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
}

.slider-section .owl-nav {
  margin: 0;
  position: absolute;
  right: 0;
  top: -58px;
}

.slider-section .owl-carousel .owl-nav .owl-prev {
  background: #1351c1;
  border-radius: 0;
  color: #fff;
  font-size: 18px;
  margin: 0 0 0 6px;
  width: 24px;
}

.slider-section .owl-carousel .owl-nav .owl-next,
.slider-section .owl-carousel .owl-nav .owl-prev {
  background: #1351c1 !important;
  border-radius: 0;
  color: #fff;
  font-size: 18px;
  margin: 0 0 0 6px;
  width: 24px;
}

.cate-head {
  color: #1f1f37;
  /* font-family: Bahnschrift, sans-serif; */
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 1.5rem !important;
  font-family: Bahnschrift, sans-serif;
}

.product-categories .owl-theme a {
  width: 127px;
  height: 127px;
  display: flex;
  padding: 1rem;
  background: #fff;
  border-radius: 3%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(128, 128, 128, 0.123);
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.feature-product-blk{
  width: 84%;
  margin: auto;
  margin-top: 40px;
  
}
.feature-product-blk1{
  width: 100%;
  margin: auto;
 
  
}
.owl-stage{
  width: 100%;
  height: 160px;
}
.star-rating button{
  padding: 2px;
}
.product-categories .owl-theme a img {
  width: 45px;
}

.product-categories .owl-theme a h5 {
  font-size: 13px;
  color: #555;
  margin-top: 0.3rem;
  text-align: center;

}

.heading-bline::before {
  content: "";
  width: 10%;
  height: 3px;
  background-color: #1351c1;
  position: absolute;
  bottom: -8px;
 
}
.heading-it-services::before {
  content: "";
  width: 45%;
  height: 3px;
  background-color: #1351c1;
  position: absolute;
  bottom: -8px;
 
}
/* .heading-bline::after {
    content: "";
    width: 13px;
    height: 13px;
    position: absolute;
    background-color: #767676;
    bottom: -18px;
    left: -2px;
    border-radius: 50%;
} */

.categories-slider .owl-carousel .owl-item>img {
  width: 127px;
}

.new-arrivals {
  border-radius: 10px;
  background: #fff;
  /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1); */
  /* padding: 25px; */
}

.new-arrivals.products-nr .owl-item {
  /* border: 1px solid #eee; */
  /* padding: 0 0.2rem; */
  border-radius: 0 0.4rem;
  /* margin: 20px; */
}
.new-arrivals.products-nr .owl-item .text-center h5{
  font-size: 14px;
}
.new-arrivals.products-nr .owl-item .text-center h4{
  font-size: 12px;
  padding-bottom: 10px;
  
}

.new-arrivals.service-nr.owl-carousel .owl-stage {
  width: 100% !important;
}

.new-arrivals .owl-item>div img {
  /* width: 219px; */
  width: 94%;
  height: 250px;
  margin: auto;
}

.new-arrivals.service-nr .owl-item>div img {
  height: auto;
}

.new-arrivals .owl-nav {
  top: -40px !important;
}

.new-arrivals.service-nr h5 {
  font-size: 13px;
}


.professional-parent .service-nr .owl-carousel .owl-nav.disabled {
  display: block !important;
}

.professional-parent .service-nr .owl-carousel .hr-2row {
  /* height: 345px; */
  height: auto;
}


.new-arrivals.service-nr .prof-img {
  width: fit-content;
  margin: 9px auto;
  background: #ecf1fb;
  border-radius: 9px;
  padding: 20px 20px;
  width: 98.235px;
  height: 108.76px;
}

.new-arrivals.prf-client .prof-img {
  /* width: 135.056px; */
  width: 100%;
  height: 95.995px;
  border-radius: 9px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px #e1e1e1fe;
}

.new-arrivals.prf-client .prof-img>img {

  max-width: 111.174px;
  max-height: 76.5px;
}

.new-arrivals.prf-client .card {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.new-arrivals.prf-client .card > img {
  max-width: 129.6px;
  max-height: 84.522px;
}

.digital-slider-img .owl-carousel .owl-item img {
  height: 242px;
  width: 100%;
  /* border-radius: 8px; */
}

.prof-img {
  padding: 30px 10px;
  border-radius: 9px;
  background: #ecf1fb;
}

.new-arrivals h5 {
  color: #000;
  font-family: Bahnschrift, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
}

.new-arrivals h4 {
  color: #666;
  font-family: Bahnschrift, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 20px;
}

.feature-pro-blk {
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  background: #fff;
  margin: 0 0 20px;
  padding: 10px;
}

.feat-pro-img {
  display: inline-block;
  width: 100px;
  vertical-align: top;
}

.fea-pro-cont {
  width: calc(100% - 100px);
  display: inline-block;
  vertical-align: top;
  padding: 10px;
}

.fea-pro-cont h3 {
  color: #000;
  font-family: Bahnschrift, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fea-pro-cont p {
  margin: 0;
  letter-spacing: -2px;
}

.fea-pro-cont .star-rate-show {
  text-align: left;
  margin: 0 0 5px;
}

.fea-pro-cont .star-rate-show i {
  padding-right: 0px;
  font-size: 10px;
}

.feature-pro-blk {
  border: 1px solid #eeeeee;
  margin: 0 0 20px;
}

.currency-section {
  display: flex;
  align-items: baseline;
  gap: 2px;
}

.currency-symbol-offer {
  color: #1351c1;
  font-family: Bahnschrift, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0rem;
}

.currency-symbol-price {
  color: gray;
  font-size: 17px;
  text-decoration: line-through;
  margin-top: 3px;
  margin-bottom: 0rem;
}

.elec-cos-blk {
  min-height: 180px;
  background-size: cover !important;
  display: flex;
  padding: 30px;
  align-items: center;
}

.elctro-blk h4 {
  font-weight: bold;
  font-size: 24px;
  color: #fff;
  margin: 0;
}

.elctro-blk p {
  font-size: 16px;
  color: #fff;
  margin: 0 0 5px;
}

.elctro-blk a {
  font-weight: 500;
  color: #fff !important;
  text-transform: uppercase;
}

.elctro-blk p span {
  color: #ffc120;
}

.elctro-blk.cosmatic-blk h4 {
  color: #111111;
}

.elctro-blk.cosmatic-blk p {
  color: #555555;
}

.elctro-blk.cosmatic-blk p span {
  color: #fe2424;
}

.elctro-blk.cosmatic-blk a {
  color: #fff !important;
}

.btn-shop-white {
  background: #1351c1 !important;
  text-transform: uppercase;
  color: #fff;
  font-family: Bahnschrift, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.discover-section h1 {
  color: #000;
  font-family: Bahnschrift, sans-serif;
  font-size: 27px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 1.5rem !important;
  /* border-bottom: 1px solid blue; */
}

.discover-ist-col {
  border-radius: 9px;
  border: 1px solid #f8f8f8;
  background: #fff;
  padding: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}


.discover-ist-col > img{
  max-width: 400px;
  max-height: 311px;
}

.discover-ist-col .row img{
 max-width: 122.927px;
 max-height: 200px;
}

.discover-ist-col h2 {
  color: #000;
  font-family: Bahnschrift, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.discover-ist-col ul li {
  list-style-type: none;
  color: #888;
  font-family: Bahnschrift, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.discover-ist-col ul {
  padding-left: 0rem;
}

.product-head {
  color: #000;
  font-family: Bahnschrift, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0px;
  margin-bottom: 0px;
}

.feature-products-cards {
  border-radius: 9px;
  border: 1px solid #cfcdcd4d;
  background: #fff;
  cursor: pointer;
}

.feature-products-cards>img {
  border-radius: 0.5rem 0.5rem 0 0;
}

.feature-header {
  display: flex;
  justify-content: space-between;
}

.feature-header ul {
  display: flex;
  list-style-type: none;
  padding-left: 0rem;
  gap: 15px;
  padding-right: 4rem;
}

.home-interior-section {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-top: 20px;
}

.bearing-section .owl-nav {
  display: none !important;
}

.by-brands .owl-nav {
  display: none !important;
}

.products-bg-img {
  background-image: url(../../../public/assets/images/bearing-1.png);
  height: 100%;
  background-repeat: no-repeat;
  padding: 50px;
}

.products-bg-img h1 {
  color: #000;
  font-family: Bahnschrift, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}

.products-bg-img p {
  color: #000;
  font-family: Bahnschrift, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.17px;
}

.products-bg-img button {
  border-radius: 9px;
  border: 1px solid #1351c1;
  background: #1351c1;
  color: #fff;
  font-family: Bahnschrift, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  padding: 5px 15px;
}

.brand {
  border-radius: 8px;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.brand h4 {
  color: #000;
  font-family: Bahnschrift, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
}

.brand h5 {
  color: #555;
  font-family: Bahnschrift, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer-bg {
  background: #002a5b;
}

.footer-section h1 {
  color: #fff;
  font-family: Bahnschrift, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.footer-section h2 {
  color: #fff;
  font-family: Bahnschrift, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.social-hide-on-desktop {
  display: none;
}
.footer-section hr {
  color: #fff;
}

.discalimer p {
  color: #fff;
  font-family: Bahnschrift, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.popular-category {
  list-style-type: none;
  padding-left: 0rem;
  color: rgba(255, 255, 255, 0.75);
  font-family: Bahnschrift, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  flex-wrap: wrap;
}

.popular-category li {
  padding-right: 5px;
  cursor: pointer;
}

.footer-links {
  list-style-type: none;
  padding-left: 0rem;
  color: rgba(255, 255, 255, 0.75);
  font-family: Bahnschrift, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
}

.footer-cols {
  display: grid;
  /* grid-template-columns: 20% 20% 20% 20% 20%; */
  grid-template-columns: 25% 16% 16% 20% 20%;
}

.dropdown-header {
  color: #f3f3f3;
  font-family: Bahnschrift, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: flex;
}

.copy-right {
  color: #fff;
  font-family: Bahnschrift, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  padding-bottom: 15px;
  text-align: center;
}

.custom-dropdown {
  cursor: pointer;
  border-right: 2px solid #b9b9b9;
  padding: 0px 17px;
}

.header-right-section .custom-dropdown:last-child {
  border-right: 0;
}

.header-right-section .custom-dropdown:first-child .dropdown-header {
  font-size: unset;
}

.dropdown-options {
  position: absolute;
  background: #fff;
  top: 46px;
  width: 350px;
  fill: #fff;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.31));
  border-radius: 4px;
  z-index: 99;
}

/* .dropdown-options::before {

} */

.dropdown-options1 {
  position: absolute;
  background: #fff;
  top: 46px;
  right: -6px;
  width: 180px;
  fill: #fff;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.31));
  border-radius: 8px;
  z-index: 999;
  padding-left: 0rem;
  list-style-type: none;
}

.dropdown-options1::before,
.dropdown-options::before,
/* .dropdown-options2::before, */
.shipped-dropdown::before,
.currency-drop::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-bottom: 17px solid #fff;
  position: absolute;
  top: -10px;
  left: 40%;
  /* box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.10); */
}

.dropdown-options::before {
  right: 4%;
}

.dropdown-options2::before {
  left: 32%;
}

.currency-drop::before {
  left: 60%;
}

.shipped-dropdown::before {
  left: 60%;
}

.dropdown-options2 {
  position: absolute;
  background: #fff;
  top: 46px;
  right: 200px;
  width: 250px;
  fill: #fff;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.31));
  border-radius: 8px;
  z-index: 10000 !important;
  padding: 8px 0rem;
  list-style-type: none;
}

.dropdown-options1 li:hover {
  background: #eee;
  font-weight: 600;
}

.dropdown-options2 li:hover,
.header-right-section .custom-dropdown li:hover {
  background: #eee;
  font-weight: 600;
}

.header-right-section .custom-dropdown ul a:hover {
  color: #555 !important;
}


.accordian-links {
  padding-left: 0rem;
  list-style-type: none;
  margin-bottom: 0px;
}

.accordian-links a {
  padding: 5px !important;
  color: #555 !important;
  font-family: Bahnschrift, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  justify-content: space-between;
}

.investor-details {
  width: 100%;
  padding: 0 2rem;
}

.investory-slider {
  display: flex !important;
  width: 70% !important;
  margin: auto !important;
  align-items: center !important;
  background-color: #eef3fb;
}

.investory-slider .slick-dots {
  display: none;
}

.shipped-dropdown {
  width: 350px;
  right: 0px;
  padding: 10px !important;
}

.header-container,
.mobile-header-parent {
  position: sticky;
  top: 0;
  z-index: 999;
  background: #fff;
}
.header_main_top{
  width: 100%;
  display: flex;
  margin: auto;
  justify-content: center;
}

.header-section p {
  color: #f3f3f3;
  font-family: Bahnschrift, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.header-links {
  color: #908f8f;
  font-family: Bahnschrift, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.dropdown-options h6 {
  color: #000;
  font-family: Bahnschrift, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  padding: 15px 25px 5px;
}

.shipped-dropdown h6 {
  font-size: 16px;
}

.dropdown-options li {
  color: #555;
  font-family: Bahnschrift, sans-serif;
  font-size: 14px;
  padding: 4px 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 6px;
  border-bottom: 1px solid #f0f0f0;
  height: 31px;
}

.dropdown-options li a {
  color: #555 !important;
}

.shipped-dropdown button {
  border-radius: 10px;
  border: 1px solid #1351c1;
  background: #1351c1;
  color: #fff;
  font-family: Bahnschrift, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  width: 100%;
  text-align: center;
  margin-top: 15px;
  padding: 7px;
}

.shipped-dropdown input {
  font-size: 14px;
  border-radius: 10px !important;
  padding: 7px !important;
}

.dropdown-options li a:hover {
  background: #eee;
  color: #1351c1 !important;
}

.dropdown-options1 li {
  color: #555;
  font-family: Bahnschrift, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 9px 20px;
  border-bottom: 1px solid #f0f0f0;
}

.dropdown-options2 li {
  color: #555;
  font-family: Bahnschrift, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 5px 20px;
  border-bottom: 1px solid #f0f0f0;
  height: 31px;
}

.header-accordian button {
  padding: 8px 15px !important;
  color: #555;
  font-family: Bahnschrift, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  justify-content: space-between;
}

/* .header-accordian .accordion-body {
    padding: 5px !important;
} */

.all-startup {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 16px;
  padding: 8px 15px !important;
  border-bottom: none !important;
}

.header-accordian .accordion-item {
  border: none !important;
  border-radius: 0px !important;
}

.header-accordian .accordion-item:not(:last-child) {
  border-bottom: 1px solid #8888884a  !important;
}

.header-accordian .accordion-button::after {
  margin-left: 30px !important;
}

.header-accordian .accordion-body {
  padding: 0px !important;
}



/*--------------------------------- 
|  Start media query 
-----------------------------------*/

@media (max-width: 1199px) {
  .header-section .mid-para {
    display: none;
  }

  /* second section  */
  .second-section-cols {
    display: grid;
    grid-template-columns: 27% 45% 27%;
  }

  /* third section  */
  .third-section h2 {
    font-size: 50px;
    line-height: 57px;
  }

  .counter-part span {
    font-size: 45px;
  }

  /* services sell cards  */
  .counter-part1 span {
    font-size: 24px;
    line-height: 30px;
  }

  .help-points img {
    height: 60px;
  }

  .our-comp h1 {
    font-size: 39px;
  }

  .our-comp h1>img {
    height: 200px;
  }

  .apna-investors {
    grid-template-columns: 16% 16% 16% 16% 16% 16%;
  }

  .footer-cols {
    grid-template-columns: 30% 15% 15% 20% 20%;
  }
}

@media (max-width: 992px) {
  .cate-head {
    font-size: 21px;
  }

  /* second section  */
  .second-section-cols {
    grid-template-columns: 100%;
    grid-gap: 1rem;
    padding: 2rem 0.5rem;
  }

  /* .second-section-cols */
  .get-quotes-section,
  .second-section-cols>div:last-child>.btns {
    background-image: none;
    display: flex;
    margin-top: 0.5rem !important;
    height: fit-content;

  }

  .get-quotes-section>button,
  .second-section-cols>div:last-child>.btns button {
    width: 45%;
    margin: auto;
    align-items: center;
    color: #fff;

  }

  .get-quotes-btn {
    background: #1351c1;
  }

  .sell-on-apnashaher-btn {
    background: rgb(255 184 0);
  }

  .form-section-apna {
    width: 37%;
  }

  .content-section-apna h3 {
    font-size: 26px;
  }

  .apna-investors {
    grid-template-columns: 19% 19% 19% 19% 19%;
  }

  /* second section --------- */
  .second-section-bg {
    height: auto;
  }

  .second-section-cols {
    gap: 2rem;
  }

  /* footer  */
  /* .footer-cols {
    grid-template-columns: 25% 25% 25% 25%;
  } */
  /* ----------------- */
  .digital-slider-img .owl-carousel .owl-item img {
    height: 200px;
  }

  .footer-cols {
    grid-template-columns: 44% 23% 18% 20%;
  }
}

@media (max-width: 767px) {

  /* header   */
  .header-section {
    grid-template-columns: 100%;
    gap: 0.5rem;
  }

  .header-section>div:first-child {
    justify-content: center;
  }

  .header-section .header-right-section {
    justify-content: center;
    padding-bottom: 1rem;
  }

  /* bananer  */
  .banner-links {
    width: 100%;
  }

  .search-bar-width .hide-in-desktop input {
    border-radius: 40px !important;
  }

  .banner-bottom-part {
    flex-wrap: wrap;
  }

  .request-quotes {
    width: 49%;
    border-right: none;
  }

  /* become seller   */
  .become-seller-p {
    flex-direction: column;
    gap: 2rem;
  }

  .form-section-apna,
  .content-section-apna,
  .empower-bussiness-content {
    width: 100%;
  }

  .empower-bussiness-content h1 {
    font-size: 40px;
  }

  .empower-bussiness-content p,
  .empower-bussiness-content h1 {
    text-align: left;
  }

  .become-buyer-p {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  /* compatitive      */
  .our-comp {
    flex-direction: column;
    gap: 0.8rem;
  }

  .our-comp .our-comp-1 {
    width: 100%;
  }

  /* third section  */
  .third-section {
    grid-template-columns: 100%;
    gap: 2rem;
  }

  .third-section h2 {
    font-size: 44px;
    line-height: 50px;
  }

  .counter-part span {
    font-size: 38px;
    line-height: 50px;
  }

  .apna-investors {
    grid-template-columns: 24% 24% 24% 24%;
  }

  /* investers */
  .investor-details {
    width: 98%;
    padding: 1rem 1.5rem;
  }

  /* Disver Products */
  .discover-ist-col {
    text-align: center;
  }

  .discover-ist-col.last-child>img {
    width: 300px;
  }

  /* home-interior-section */
  .home-interior-section .poster-img {
    display: none;
  }

  /* footer  */
  .footer-cols {
    grid-template-columns: 33% 33% 33%;
  }
  .social-hide-on-desktop{
    display: block;
    margin-bottom:0.7rem;
  }
  .social-hide-on-tab {
    display: none;
  }

  /* Service Page ----- */
  /* professioanl component  */
  .professional-parent .row {
    gap: 1rem;
  }

  .discover-section .mobile-less-height>img {
    height: 300px;
    width: 330px;
  }

  .discover-section>.row {
    gap: 14px;
  }

  .new-arrivals.prf-client {
    margin: 1rem 0;
  }
}



@media(max-width: 699px) {
  .banner-services {
    line-height: 19px;
    margin-top: 0.6rem !important;
  }

  .main-banner-bg {
    height: 240px;
  }
}

@media (max-width: 600px) {

  /* banner  */
  .request-quotes {
    width: 98%;
  }

  .get-quotes-section>button,
  .second-section-cols>div:last-child>.btns button {
    width: 60%;
  }

  .product-categories .owl-theme a {
    width: 100px;
    height: 100px;
  }
  .product-categories .owl-theme a img {
    width: 35px;
  }
  .product-categories .owl-theme a h5 {
    font-size: 11px;
  }
  /* .get-quotes-section, .second-section-cols > div:last-child > .btns {
    flex-direction: column;
    gap: 0.6rem;
  } */

  .apna-investors {
    grid-template-columns: 32% 32% 32%;
    text-align: center;
  }

  .our-comp h1,
  .apna-investors-section h6 {
    font-size: 38px;
  }

  .footer-cols {
    grid-template-columns: 50% 50%;
  }

  .discalimer p {
    font-size: 17px;
  }

  .get-quotes-section>button,
  .second-section-cols>div:last-child>button {
    width: 48%;
  }
}


@media (max-width: 460px) {

  /* banner  */



  .apna-investors {
    grid-template-columns: 48% 48%;
    text-align: center;
  }

  .get-quotes-section>button,
  .second-section-cols>div:last-child>button {
    width: 48%;
    font-size: 13px;
  }

  .hr-slider.second-sec-hr-slider .slick-slide img {
    height: 250px !important;
  }

  .footer-cols {
    grid-template-columns: 100%;
  }
}

/*--------------------------------- 
|  End media query 
-----------------------------------*/
.dropdown-options.filter{
  padding-top: 10px;
  right: 467px !important;
}
.dropdown-optionsarrow::before{
right: 20% !important;
left: 8% !important;
}
.dropdown-options.right {
  left: auto !important;
  right: -100px !important;
}

.dropdown-options.left {
  left: 0 !important;
  right: auto !important;
}

.banner-bottom{
  width: 100%;
  height: auto; 
 
  /* display: flex; */
  justify-content: space-between;
}
.popular_cities{
  width: 100%;
  height: auto;
 display: flex;
 gap: 20px;
 overflow: auto;
}
.popular_cities::-webkit-scrollbar{
  display: none;
}
.popularcity_name{
  width: 130px;
  height: auto;
  border-radius: 7px;
  /* border: 1px solid #fff; */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #7c7d7f17;
  padding: 10px;
}
.popularcity_name p{
  color: #fff;
}
.popular-city-img{
  width: 100px;
  height: 100px;
  border-radius: 7px;
  /* background-color: #1350c12c; */
  display: flex;
  justify-content: center;
  
}
.popular-city-img img{
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 2px solid #fff;
}
.product_new-arival{
  width: 100%;
  height: auto;
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  flex-direction: column;

}

.product_new-arival img:hover{
  transform: rotateY(180deg);
}
.product_new-arival img{
  width: 100%;
  /* height: 250px; */
  border: 1px solid rgba(128, 128, 128, 0.295);
  border-radius: 7px;
}
.product_new-arival h5{

  font-size: 0.875rem;
        line-height: 1.25rem;
        color: #2c2c2c;
        text-align: center;
}
.product_new-arival h4{
  color: rgb(255, 187, 0);
  padding-bottom: 0px;

}
.product_new-arival h4 span{
  font-size: 12px;
  font-weight: 300;
  color: gray;
  margin-left: 10px;
  
}
.product_new-arival h3{
  font-size: 20px;
  /* color: rgb(255, 187, 0); */
}
.trending-banner-section{
  width: 100%;
  height: 300px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 30px;
  border-radius: 5px;
  overflow: hidden;
}
.trending-banner-section1{
  width: 100%;
  height: 200px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 30px;
  border-radius: 5px;
  overflow: hidden;
}