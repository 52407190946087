    /* Start Banner component ----------- */

    .main-banner-bg {
        background-image: url(../../../public/assets/images/home-banner.png);
        /* height: 100%; */
        height: 211px;
        width: 100%;
        padding: 50px 31px 30px 30px;
    }

    .products-banner {
        display: flex;
        /* gap: 20px; */
        list-style-type: none;
        color: #fff;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        justify-content: center;
        padding-left: 0rem;
    }

    .products-banner a {
        color: #fff;
    }

    .products-banner>a:not(:last-child) {
        border-right: 2px solid #fff;
        padding: 0px 10px 0px 10px;
    }
    .products-banner a {
        /* border-right: 2px solid #fff; */
        padding: 0px 10px 0px 10px;
    }
    .search-bar-width {
        width: 600px;
        margin: auto;
    }

    .searchheade-block {
        background: #fff;
        border-radius: 30px;
        overflow: hidden;
        position: relative;
    }

    .searchheade-block .input-group {
        border: none;
    }

    .searchheade-block .input-group .input-group-text {
        background: transparent;
        border: none;
        width: 150px;
        font-weight: 600;
    }

    .searchheade-block .form-select {
        border: none;
        font-size: 14px;
        font-weight: 600;
    }

    .searchheade-block .form-control {
        border: none;
        border-left: 1px solid #ddd;
        font-size: 14px;
        min-height: 35px !important;
        font-weight: 600;
    }

    .hide-in-desktop {
        display: none;
    }

    .banner-links input {
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
    }

    /* banner bottom content  */

    .banner-bottom-part {
        overflow: scroll;
        width: 100%;
        margin: auto;
        height: fit-content;
        top: -25px;
        position: relative;
        border-radius: 10px;
        padding: 0 0 0.2rem 0;
        border-radius: 10px;
    }

    .banner-bottom-part::-webkit-scrollbar {
        display: none;
    }

    .banner-bottom-part .banner-bottom-content {
        width: 84%;
        border-radius: 10px;
        background: #0634b404;
        /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1); */
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 10px;
        margin: auto;
        border: 1px solid #c4c4c4;
       
        /* top: -20px; */
    }

    .banner-bottom-part .banner-bottom-content h6 a {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
    }

    .banner-bottom-part .banner-bottom-content p {
        color: #908f8f;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
    }

    .request-quotes {
        display: flex;
        width: 100%;
        gap: 15px;
        align-items: center;
        border-right: 1px solid #c4c4c4;
        justify-content: center;
        padding: 20px 0px;
    }

    .request-quotes:nth-child(4) {
        border: none;
    }

    /* End Banner component ----------- */
    /* Start Products Card component  */

    .apna-pro-bg {
        border-radius: 9px;
        border: 1px solid #eeeeee;
        background: #fff;
        /* box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.1); */
        /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
        padding: 10px;
    }

    .apna-pro-bg h6 {
        color: #302f2f;
    font-family: Bahnschrift, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 15px;
    margin-bottom: 15px;
    }

    .apna-pro-pera {
        display: none;
    }

    .counter-part1 {
        width: 45%;
    }

    .counter-part1 span {
        color: #1351c1;
        font-family: Bahnschrift, sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
    }

    .counter-part1 p {
        color: #000;
        font-family: Bahnschrift, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    /* End Products Card component  */
    /* Start Empower Businness component  */

    .empower-bussiness-content {
        /* width: 80%; */
        margin: auto;
        text-align: center;
        margin-top: 70px;
        margin-bottom: 22px;
    }

    .empower-bussiness-content h1 {
        color: #1f1f37;
        text-align: center;
        font-family: Bahnschrift, sans-serif;
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        font-family: Bahnschrift, sans-serif;
    }

    .empower-bussiness-content p {
        color: #555;
        text-align: center;
        font-family: Bahnschrift, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 82%;
        margin: auto;
        line-height: 1.75rem;
      
        font-family: Bahnschrift, sans-serif;
    }

    .empower-bussiness-content-vertical h1 {
        color: #1f1f37;
        text-align: start;
        font-family: Bahnschrift, sans-serif;
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        font-family: Bahnschrift, sans-serif;
    }

    .empower-bussiness-content-vertical p {
        color: #555;
        text-align: start;
        font-family: Bahnschrift, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 100%;
        margin: auto;
        line-height: 1.75rem;
      
        font-family: Bahnschrift, sans-serif;
    }
    .empower-bussiness-content-vertical button{
        background-color: #002a5b;
        border-radius: 4px;
        height: 45px;
        color: #fff;
        margin-top: 30px;
        padding: 0px 20px;
    }
    .vertical-steper-right{
        width: 100%;
        height: auto;
        display: grid;
    grid-template-columns: 1fr ;
    gap: 20px;
    }
    .vertical-steper-card{
        width: 100%;
        height: auto;
        border: 1px solid #eeeeee;
        border-radius: 6px;
        display: grid;
        grid-template-columns: 1fr 11fr;
        gap: 15px;
        padding: 15px;
    }
    .vertical-steper-card h4{
        font-family: Bahnschrift, sans-serif;
    }
    /* End Empower Businness component  */
    /* Start Become seller component   */
.updated-buttons button{
    border-radius: 4px;
    background: #ffb800;
    color: #000;
    font-family: Bahnschrift, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    margin-top: 10px;
    padding: 7px;
    height: 45px;
    margin-bottom: 20px;
}
    .form-section-apna {
        width: 600px;
        border-radius: 9px;
        border: 1px solid #ccc;
        background: #002a5b;
        padding: 25px;
    }

    .form-section-apna h6 {
        color: #fff;
        font-family: Bahnschrift, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .form-section-apna h6 {
        color: #fff;
        font-family: Bahnschrift, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .form-section-apna h6 {
        color: #fff;
        font-family: Bahnschrift, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .form-section-apna p {
        color: #ffffff;
        font-family: Bahnschrift, sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 10px;
    }

    .form-section-apna button {
        border-radius: 4px;
        background: #ffb800;
        color: #000;
        font-family: Bahnschrift, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 100%;
        margin-top: 10px;
        padding: 7px;
        height: 45px;
    }
    .form-section-apna select{
        border-radius: 4px !important;
        border: 1px solid #b9b9b9;
        background: #fff;
        color: #908f8f !important;
        font-family: Bahnschrift, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 15px;
        height: 42px;
    }
    .form-section-apna input {
        border-radius: 4px !important;
        border: 1px solid #b9b9b9;
        background: #fff;
        color: #908f8f !important;
        font-family: Bahnschrift, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 15px;
        height: 42px;
    }

    .form-section-apna input[type="radio"] {
        width: 0.9rem !important;
        height: 0.9rem !important;
        border-radius: 50% !important;
        margin-bottom: 0.8rem;
    }

    .form-section-apna input[type="checkbox"] {
        width: 1rem !important;
        height: 1rem !important;
        margin-bottom: 0.7rem;
        margin-top: 8px;
    }

    .form-section-apna .form-check-input:checked[type=radio],
    .form-section-apna .form-check-input:checked[type=checkbox] {
        background: #ffb800;
        border: 2px solid #ffb800;
    }

    .form-section-apna label.form-check-label {
        font-size: 11px;
        /* margin-left: 10px; */
    }

    .form-section-apna .sel-product-service>div {
        width: 60%;
        text-align: start;
    }

    .form-section-apna .sel-product-service label {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 14px;
    }

    .form-section-apna textarea {
        border-radius: 4px !important;
        border: 1px solid #b9b9b9;
    }

    /* .form-section-apna input:last-child { */

    .form-section-apna select {
        border-radius: 4px !important;
        border: 1px solid #b9b9b9;
        background: #fff;
        color: #595656 !important;
        font-family: Bahnschrift, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 15px;
        padding: 10px;
        height: 42px;
    }

    /* .content-section-apna {
  width: 60%;
} */

    .content-section-apna p {
        color: #555;
        font-family: Bahnschrift, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0.3rem;
    }

    .content-section-apna h3 {
        color: #000000;
        font-family: Bahnschrift, sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .content-section-apna h5 {
        color: #555;
        font-family: Bahnschrift, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0px;
    }

    .help-points {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        margin-top: 15px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.144);
        padding-bottom: 10px;
    }

    .help-points>img {
        width: 75px;
        height: 75px;
        margin-right: 20px;
    }

    /* End Become seller component   */
    /* Start Businss Steps compnent  */
    /* End Businss Steps compnent  */
    /* Start Our comptitors compnent  */

    .our-comp-bg {
        background: rgb(255, 255, 255);
        padding: 40px;
        margin-top: 30px;
    }

    .our-comp h1 {
        color: #1f1f37;
        text-align: start;
        
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        font-family: Bahnschrift, sans-serif;
    }

    .our-comp p {
        color: #555;
        font-family: Bahnschrift, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .our-comp button {
        border-radius: 29px;
        background: #1351c1;
        color: #fff;
        font-family: Bahnschrift, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 8px 25px;
    }

    .our-comp {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .our-comp-1 {
        width: 45%;
    }



    /* End Our comptitors compnent  */
    /* Start Investors component  */

    .apna-investors-section h6 {
        color: #1f1f37;
        text-align: center;
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        font-family: Bahnschrift, sans-serif;
    }

    .apna-investors-section p {
        color: #555;
        font-family: Bahnschrift, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: justify;
    }

    .overf-div3 {
        max-width: 100%;
        overflow-x: auto;
    }
    .overf-div3::-webkit-scrollbar {
        display:none;
    }
    .apna-investors {
        min-width: 1080px;
    }

    .apna-investors-section .slick-dots {
        display: none !important;
    }

    .apna-investors-section .slick-slider>button {
        display: none !important;
    }

    .apna-investors-section .investor-details>p {
        font-size: 20px;
        margin: 4.5rem 0;
    }

    .apna-investors-section h2 {
        color: #555;
        font-family: Italiana;
        font-size: 66px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin-bottom: 1.5rem;
    }

    .apna-investors-section ul.slick-dots {
        display: none;
    }

    .apna-investors {
        display: grid;
        grid-template-columns: 11% 11% 11% 11% 11% 11% 11% 11%;
        gap: 17px;
    }

    .apna-investors p {
        text-align: center;
    }

    .apna-user {
        border-radius: 9px;
        border: 1px solid #cfcfcf;
        background: #fff;
    }

    .apna-user>img {
        width: 100%;
    }

    .apna-user h3 {
        color: #000;
        text-align: center;
        font-family: Bahnschrift, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 10px;
    }

    /* End Investors component  */
    /* Start moble footer   */

    .mobile-footer {
        min-width: 320px;
        max-width: 460px;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 994;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.35);
        display: none;
    }

    .mobile-footer .main-content {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.35);
        padding: 0 0.5rem;
    }

    .mobile-footer .main-contnent>div {
        width: fit-content;
        /* padding: 0.5rem 0.8rem; */
        text-align: center;
    }

    .mobile-footer .main-contnent .home>a {
        position: absolute;
        top: -2.5rem;
        left: 9px;
        padding: 0.33rem;
        background: linear-gradient(1deg, #a5a5a5, transparent, transparent);
        border-radius: 50%;
    }

    .mobile-footer .main-contnent .home>a>div {
        width: 51px;
        height: 51px;
        background: #1351c1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        z-index: 5;
        position: relative;
    }

    /* .mobile-footer .main-contnent.home > a::after  {
    content: "";
    height: 3rem;
    height: 100%;
    background-image: linear-gradient( 135deg, #A0FE65 10%, #FA016D 100%);
    position: absolute;
    left: 0;
    bottom: -1px;
    z-index: 2px

    } */

    .mobile-footer .main-contnent .more>a span {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #000;
    }

    .mobile-footer .main-contnent>div P {
        color: #555;
        font-size: 12px;
        font-weight: 600;
    }

    /* End moble footer   */


    /* Start new Mobile Banner css--------------- */
    .show-mobile-banner {
        display: none;
    }
    .show-desktop-banner{
        display: block;
    }

    .mobile-banner {
        padding: 1rem;
        padding-top: 1.5rem;
        max-width: 460px;
    }

    .mobile-banner-bg {
        height: 190px;
        width: 100%;
        background: url("../../../public/assets/images/btop.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover; /* Ensures the image covers the area */
    }
    

    .mobile-banner .banner-links ul li>div {
        width: 100%;
        height: 100%;
        gap: 0.6rem !important;
    }

    .mobile-banner .banner-links ul li {
        width: 92px;
        height: 92px;
        text-align: center;
        border-radius: 0.4rem;
        background: linear-gradient(180deg, #FF40E0 0%, #005A80 100%);
        color: #FFF;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1rem;
        padding-right: 0;
        /* 114.286% */

    }

    .mobile-banner .products-banner {
            min-width: 402px;
    }

    .mobile-banner .banner-links ul li.lcard2 {
        background: linear-gradient(180deg, #A41FD2 0%, #8D3232 100%);
    }

    .mobile-banner .banner-links ul li.lcard3 {

        background: linear-gradient(180deg, #631FD2 0%, #93147F 100%);
    }

    .mobile-banner .banner-links ul li.lcard4 {
        background: linear-gradient(180deg, #1FD29C 0%, #4A328D 100%);
    }

    .mobile-banner .banner-links ul li.slcard1 {
        background: linear-gradient(180deg, #113E91 0%, #629AFF 100%);
    }

    .mobile-banner .banner-links ul li.slcard2 {
        background: linear-gradient(180deg, #FFAE00 0%, #E76D06 100%);
    }

    .mobile-banner .banner-links ul li.slcard3 {
        background: linear-gradient(180deg, #C524FE 0%, #DA72FF 100%);

    }

    .mobile-banner .banner-links ul li.slcard4 {
        border-radius: 4px;
        background: linear-gradient(180deg, #539797 0%, #116BBE 100%);
    }

    /* #A41FD2, #8D3232 */

    .mobile-banner .products-banner>a:not(:last-child) {
        border-right: 0px !important;
    }

    .mobile-banner .banner-links .back-btn button {
        color: #E5E5E5;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
    }

    .mobile-banner .banner-links .overf-div2 {
        max-width: 100%;
        overflow-x: auto;
    }

    .mobile-banner .banner-links .overf-div2::-webkit-scrollbar {
        display: none;
    }


    /* End new Mobile Banner css -----------------*/
    /* -----------------------------------------------------------
| Start Media 
--------------------------------------------------------- */

    @media (max-width: 1199px) {
        .pd-cards .row>div {
            padding: 0 0.2rem;
        }

        .form-section-apna {
            width: 45%;
            gap: 3%;
        }

        .buss-stepper .Mui-active {
            font-size: 37px;
        }

        /* .apna-investors {
            grid-template-columns: 17% 17% 17% 17% 17%;
        } */

        .apna-investors-section .investor-details>p {
            font-size: 19px;
            margin: 1.5rem 0;
        }

        .apna-investors-section .investor-details>p {
            font-size: 19px;
            margin: 1.5rem 0;
        }
    }

    @media (max-width: 1080px) {
        .banner-bottom-part {
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        }
    }

    @media (max-width: 992px) {
        .empower-bussiness-content h1 {
            font-size: 35px;
        }

        .empower-bussiness-content p {
            width: 100%;
        }

        .form-section-apna {
            width: 60%;
            margin: auto;
        }

        .become-seller-p {
            flex-direction: column;
        }

        .become-buyer-p {
            flex-direction: column-reverse;
            gap: 1rem;
        }

        .our-comp h1 {
            font-size: 30px;
        }

        .our-comp-1 img {
            height: 230px !important;
        }

        .buss-stepper .Mui-active {
            font-size: 28px;
        }

        /* .form-section-apna {
    width: 40%;
  } */
        .third-section h2 {
            font-size: 40px;
            line-height: 49px;
        }

        .counter-part span {
            font-size: 37px;
            line-height: 57px;
        }

        .content-section-apna h3 {
            font-size: 28px;
        }

        .help-points {
            gap: 13px;
        }

        .help-points img {
            width: 55px;
            height: 55px;
        }

        .apna-investors-section .investor-details>p {
            margin: 3rem 0;
        }

        .investory-slider,
        .apna-pro-bg>img {
            width: 100% !important;
        }
    }

    @media (max-width: 767px) {

        /* .request-quotes {
  flex-direction: column;
  padding: 1.4rem 1rem ;
} */
        .main-banner-bg {
            height: 236px;
        }

        .third-section h2 {
            font-size: 37px;
        }

        /* .apna-pro-bg {
    text-align: center !important;
  } */
        .empower-bussiness-content {
            width: 100%;
        }

        .empower-bussiness-content>.row {
            gap: 10px;
        }

        .empower-bussiness>.row>div:first-child {
            margin-bottom: 1rem;
        }

        .form-section-apna,
        .content-section-apna {
            width: 100%;
        }

        /* .apna-investors {
            grid-template-columns: 23% 23% 23% 23%;
        } */

        .investory-slider>div:last-child {
            display: none;
        }

        .apna-investors-section h6 {
            font-size: 40px;
        }

        .apna-investors-section h2 {
            font-size: 55px;
        }
    }

    @media (max-width: 600px) {
        /* .apna-investors {
            grid-template-columns: 30% 30% 30%;
        } */

        .search-bar-width {
            width: 100%;
        }
    }

    @media (max-width: 575px) {
        .our-comp-1>.row {
            gap: 10px;
        }

        .our-comp>.our-comp-1:last-child {
            text-align: center !important;
        }

        .our-comp-1 h1 {
            font-size: 35px;
        }
    }

    @media (max-width: 530px) {
        .products-banner {
            font-size: 18px;
        }

        .products-banner>a:not(:last-child) {
            padding-right: 8px;
        }
    }

    @media (max-width: 460px) {
        .show-desktop-banner{
            display: none;
        }
    .show-mobile-banner{
        display: block;
    }
        /* .main-banner-bg {
            padding-top: 35px;
        } */

        .hide-in-desktop {
            width: 100%;
        }

        .search-bar-width .hide-in-desktop img {
            width: 15px;
            height: 15px;
        }

        .form-control {
            font-size: 0.8rem;
            height: 34px;
        }

        .products-banner {
            font-size: 14px;
            line-height: 15px;
            gap: 6px;
        }

        .products-banner>a:not(:last-child) {
            padding-right: 5px;
        }

        .products-banner li {
            padding-right: 3px;
        }

        .banner-services {
            font-size: 12px;
        }

        .request-quotes {
            gap: 10px;
            width: 27%;
            padding: 0px 9px;
        }

        .request-quotes img {
            width: 2rem;
        }

        .banner-bottom-part {
            width: 90%;
        }

        .banner-bottom-part .banner-bottom-content h6,
        .banner-bottom-part .banner-bottom-content h6 a {
            font-size: 14px;
            line-height: 14px;
        }

        .apna-investors-section h6 {
            font-size: 28px;
        }

        .apna-investors-section h2 {
            font-size: 44px;
        }

        .empower-bussiness-content p {
            width: 100%;
        }

        .apna-investors-section .investor-details>p {
            margin: 1.5rem 0;
            font-size: 17px;
        }

        .mobile-footer {
            display: block;
        }

        .empower-bussiness-content h1 {
            font-size: 29px;
        }

        .third-section h2 {
            font-size: 31px;
            line-height: 39px;
        }

        .counter-part span {
            font-size: 36px;
            line-height: 43px;
        }

        .our-comp-bg {
            /* padding: 25px; */
            padding: 14px;
        }
        .our-comp-1  img {
            width: 100%;
        }

        .our-comp h1 {
            font-size: 31px;
        }

        .our-comp .our-comp-1 .row>div {
            margin-top: 0.6rem;
        }

        .apna-investors {
            gap: 10px;
        }
    }

    .breadcrumb-sction {
        display: none;
    }

/* -----------------------------------------------------------
| End Media 
--------------------------------------------------------- */
.top-banner-bg {
    /* background-image: url(../../../public/assets/images/vid-bg.mp4); */
    /* height: 100%; */
    height: 540px;
    width: 100%;
    padding: 50px 31px 30px 30px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 60px;
}
.background-video-container {
    position: relative;
    height: 540px;
    width: 100%;
    overflow: hidden;
  }
  
  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover; /* Ensures the video scales nicely */
    z-index: -1; /* Place the video behind the content */
  }
  
  .content {
    position: relative;
    z-index: 1; /* Ensure content is above the video */
    padding: 50px 30px;
    color: white; /* Contrast text for visibility */
    text-align: center;
  }
  
.top-banner_section{
    width: 100%;
    height: 100%;
    display: flex;
  justify-content: center;
    flex-direction: column;
}
.top-banner_section h1{
position: relative;
top: 120px;
    font-size: 60px;
    font-weight: 800;
    color: #fff;
    text-align: center;
    
}
.top-banner_section h1 span{
    color: red;
}
.text-product h6:hover{
    color: red;
    cursor: pointer;
}
.text-product:hover{
    color: red;
    cursor: pointer;
}
.home_page_nav_link{
    width: 100%;
    height: 410px;
    display: flex;
    justify-content: space-between;
    
}
.home_page_left_nav{
    width: 25%;
    height: 353px ;
    /* background-color: #000000; */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    /* border-radius: 10px; */
}
.home_page_right_nav{
    width: 73.3%;
    height: 337px ;
    /* background-color: #000000; */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 15px;
}
.banner_top_section{
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #dfdfdf;
    border-top: 1px solid #dfdfdf;
}
.category_section{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.category_section1{
    width: 100%;
    height: 100%;
    display: flex;
    /* justify-content: space-between; */
    /* margin-top: 10px; */
    gap: 23px;
}
.home_page_left_nav1{
    width: 25%;
    height:60px ;
    
    background: #f3f3f3;
    border: 1px solid #dfdfdf;
    border-top: none;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
}
.home_page_right_nav1{
    width: 72%;
    height:60px ;
   
    border: 1px solid #dfdfdf;
    border-top: none;
    border-left: none;
    border-right: none;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* justify-content: space-between; */
}
.updated-home-nav{
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;  
    
}
.updated-home-nav h5{
    color: #2c2c2c;
    font-size: 1rem;
    line-height: 1.5rem;
}
.cat-line{
    width: 1px ;
    height: 30px;
    background-color:#dfdfdf ;
    margin-left: 20px;
    margin-right: 20px;
}
.home_page_right_nav1 h5{
    color: #2c2c2c;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0px;
    font-weight: 400;
    cursor: pointer;
}
.home_page_right_nav1 h5:hover{
    border-bottom: 2px solid blue;
}
.home-left-category{
    margin-left: 0px !important; 
}
.home-left-category .h-cate{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    border-bottom: 1px solid #dfdfdf;
    margin-left: 0px !important;
    align-items: center;
    transition: all 0.2s ease;
}

.home-left-category .h-cate:last-child {
    border-bottom: none; /* Removes the bottom border for the last element */
}
.h-cate.selected {
    background-color: #1351c1;
    color: #fff;
}
.h-cate1.selected {
    /* background-color: #1351c1; */
    color: #fff !important;
}
.home-left-category .h-cate h5{
   
  font-weight: 500;
    font-size: 0.875rem;
   cursor: pointer;
   color: #898989;
    margin: 0px !important;
}
.home-left-category .h-cate:hover{
    background-color: #1351c1;
    cursor: pointer;
    color: #fff !important;
    padding-left: 30px;
    /* transform: translateX(20px);   */
    }
    .home-left-category .h-cate:hover h5 {
        color: #fff; /* Change the color of h5 on hover */
      }
      .updated-product-card{
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap:20px;
      }
      .p_card{
        width: 100%;
        height: 350px;
        background-color: #ffffff0e;
        cursor: pointer;
        border-radius: 16px;
        display: flex;
        padding: 20px;
        flex-direction: column;

        gap: 20px;

      }
      .left_icon h5{

        margin: 0px;
        padding: 0;
        color: #000;
        font-size: 10px;
      }
      .left_icon h4{
        color: #fff !important;
      }
      .left_icon{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .p_card_icon{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .product-card_bg{
        width: 100%;
        padding: 60px;
        background-color: #002a5b;
      }
      .p_card h2{
        color: #fff;
        margin: 0px;
        
      }
      .p_card p{
        /* text-align: justify; */
font-weight: 400;
        color: #fff;
      }
      .p_card:hover{
background-color: #ffffff2d;

      }
      .empowering-section{
        width: 100%;
        height: auto;
        /* display: flex; */

      }
      .empowering-image-section{
        width: 100%;
        height: 350px;
        border-radius: 15px;
        overflow: hidden;
      }
      .empowering-image-section img{
        width: 100%;
        height: 100%;
      }
      .empowering-bottom-card{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;
        margin-top: 30px;
      }
      .empowering-card-tab{
        width: 100%;
        height: 200px;
        border-radius: 10px;
      padding: 20px;
      cursor: pointer;

      }
      .empowering-card-tab h4{
        font-size: 1.2rem;
        font-weight: 600;
        font-family: Bahnschrift, sans-serif;
      }
      .empowering-card-tab p{
        font-size: 15px;
       font-weight: 400;
        font-family: Bahnschrift, sans-serif;
      }
      .empowering-card-tab:hover{
        background-color: #e8eaeb;
      }
      .become-seller-bg{
        background-color: #f9f9f9;
        padding: 60px 0px;
        margin-top: 30px;
      }
      .become-seller-bg1{
        background-color: #002a5b;
        padding: 30px 0px;
        margin-top: 0px;
      }
      .vertical-stepper-section{
        width: 100%;
        height: 600px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }