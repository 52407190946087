/* Investor Banner */

* {
    font-family: Inter;
}


.investor-banner {
    background: linear-gradient(102deg, #221C5B 20.11%, #133462 96.24%);
}

.investor-banner h2 {
    color: #FFF;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.investor-banner .left p {

    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.investor-banner .left svg {
    width: 21px;
    height: 21px;
    fill: #FFB800;
}

.investor-banner .left .btns button {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    padding: 0.8rem;
    width: 49%;
    background: #FFB800;
    border-radius: 2px;
    white-space: nowrap;
}

/*Highlights ------------------------  */
.highlights .top-content .ldiv {
    width: 60%;

}

.highlights .top-content .rdiv {
    width: 35%;
    text-align: right;
}

.highlights .top-content .rdiv a {
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    border-radius: 2px;
    border: 1px solid #1351C1;
    background: #1351C1;
    padding: 0.7rem 3rem;

}


/* Risk ---------------- */
.overf-div {
    max-width: 100%;
    overflow-x: auto;
}

.overf-div::-webkit-scrollbar {
    display: none;
}

.risk .top-content {
    margin-bottom: 2rem;
}

.risk .top-content p {
    width: 75%;
    margin: auto;
}

.risk .cards {
    min-width: 992px;
}

.risk .cards .cmn-card {
    background-color: #fff;
    padding: 1rem;
    height: 110px;
    border-radius: 0.4rem;

}

.risk .cards .cmn-card p {
    line-height: 18px;
    margin-bottom: 0;
    background-color: #fff;

}

/* Communicate----------- */
.communicate .top-content {
    margin-bottom: 1.5rem;
}

.communicate .top-content p {
    width: 60%;
}

.communicate .cards a {
    width: 23%;
    padding: 1.2rem;
    border-radius: 0.4rem;
    display: flex;
    background: #fff;
}

.communicate .cards a>div p {
    color: #555;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 81.818% */
}

.communicate .cards a>div svg {
    fill: #908F8F;
    width: 20px;
    height: 20px;
}

/* Tab Component  */

.tab-comp {
    background-color: #e5e5f8;
}

.tab-comp .main-content .left {
    width: 25%;
}

.tab-comp .main-content .right {
    width: 60%;
}

.active-build-ms-stab {
    background: #fff;
    color: #1754c2 !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.08);
}

.tab-comp .main-content .left-content .toggle-link {
    color: #555;
    font-size: 18px;
    font-weight: 500;
    padding: 1rem 1.2rem;
    border-radius: 0.5rem;
    cursor: pointer;
}

.tab-comp .main-content .left .rounded-div {
    width: 116px;
    height: 88px;
    background: rgba(19, 81, 193, 0.12);
    border-radius: 0 0 2rem 2rem;
    margin-bottom: 2rem;
}

/* --------------------------------------------
| Media Query 
---------------------------------------------- */

@media(max-width: 1199px) {
    .investor-banner .right {
        width: 40%;
        padding: 2rem 0;
    }

    .investor-banner h2 {
        font-size: 49px;
    }

    .tab-comp .main-content .left {
        width: 30%;
    }
}

@media(max-width: 1080px) {
    .communicate .cards a {
        width: 35%;
        margin-bottom: 0.6rem;
    }
}



@media(max-width: 992px) {

    .investor-banner h2 {
        font-size: 41px;
    }

    .investor-banner .left .btns button {
        padding: 0.4rem 0.8rem;
    }

    .investor-banner .left {
        padding: 1.5rem 0;
    }

    .why-sell>div {
        flex-direction: column;
    }

    .highlights .top-content>div {
        flex-direction: column-reverse;
        /* margin-top: 2rem !important; */
        gap: 1rem;
    }

    .highlights .top-content .rdiv,
    .highlights .top-content .ldiv {
        width: 100%;
        /* text-align: start; */
    }

    .tab-comp .main-content .left .rounded-div {
        width: 100px;
        height: 70px;
    }

    .tab-comp .main-content .left-content .toggle-link {
        padding: 0.6rem 1rem;
    }

    .active-build-ms-stab {
        font-size: 18px !important;
    }

}

@media(max-width: 767px) {

   
    .investor-banner .left svg {
        width: 18px;
        height: 18px
    }

    .investor-banner h2 {
        font-size: 36px;
    }

    .risk .top-content {
        margin-bottom: 1rem;
    }

    .tab-comp .main-content {
        flex-direction: column;
        gap: 1rem;
    }

    .tab-comp .fix-content .rounded-div {
        display: none;
    }

    .tab-comp .main-content .left,
    .tab-comp .main-content .right {
        width: 100%;
    }

    .tab-comp .left .left-content {
        overflow-x: auto;
        margin-top: 1rem;
        max-width: 100%;
    }
    .tab-comp .left .left-content::-webkit-scrollbar,
    .tab-comp .left .left-content .arrow-icon {
        display: none;
    }

    .tab-comp .left .left-content ul {
        display: flex;
        flex-direction: row;
    }
  

    .tab-comp .left .left-content li {
        white-space: nowrap;
    }
    .communicate .cards a {
        width: 40%;
    }

    .communicate .top-content p {
        
    }
    /* .tab-comp .fix-content */
}

@media(max-width: 575px) {
    .investor-banner .container {
        flex-direction: column-reverse;
    }

    .investor-banner .right {
        width: 60%;
        padding: 0;
    }

    .investor-banner .left {
        padding: 0 0 2rem 0;
        text-align: center;
    }

    .highlights .top-content .rdiv a {
        padding: 0.5rem 2.5rem;
    }

    .highlights .top-content .ldiv p {
        font-size: 14px;
    }

    .why-sell .cards>div>div {
        padding: 0.6rem !important;
    }

    .risk .top-content p {
        width: 100%;
    }

    h3 {
        font-size: 28px;
    }

    .communicate .cards a {
        width: 49%;
    }
    .active-build-ms-stab{
        scale: 1;
    }
}


@media(max-width: 490px) {
    .why-sell .col-lg-8 {
        padding-left: 0 !important;
    }

    .why-sell .cards P {
        margin-bottom: 0;
    }

    .highlights .top-content .rdiv {
        margin-top: 1rem;
        text-align: left;
    }
    .communicate .cards a>div p{
        font-size: 19px;
    }
    .communicate .cards a  img{
        font-size:15px;
        
    }
}

@media(max-width: 416px) {
    .investor-banner .left .btns button {
        font-size: 14px;
    }

    .communicate .cards a {
        width: 97%;
    }
    
}