.feedback .star-rating span {
    font-size: 2.4rem;
}

.feedback .star-rating .star i {
    color: lightgrey;

}

.feedback .star-rating .star-golden i {
    color: goldenrod;
}

.feedback .feedback-form form {
    width: 60%;
    margin: auto;
    text-align: center;
}

.feedback .feedback-form form textarea,
.feedback .feedback-form form .upload-document {
    border: 2px;
    border: 2px solid #e7e7e7;
    border-radius: 0.4rem;
}



.feedback .feedback-form .upload-document>div {
    left: 30%;
    transform: translateX(-30%);
    top: 0.6rem;
}


.feedback .feedback-form button {
    background: #1351c1;
    border-radius: .2rem;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 1rem;
    padding: .6rem 5rem;

}

.feedback .feedback-form textarea,
.feedback .feedback-form .upload-document {
    background-color: whitesmoke;
}




@media(max-width:1199px) {
    .feedback .feedback-form .upload-document>div {
        left: 45%;
        transform: translateX(-45%);
    }   
}


@media(max-width:992px) {
    .feedback .feedback-form form {
        width: 80%;
    }
}


@media(max-width: 767px) {
    .feedback h3 {
        
            font-size: 31px;
    }

    .feedback .feedback-form form {
        width: 100%;
    }

    .feedback > .container {
        width: 100% !important;
    }


}


@media(max-width: 416px) {
    .feedback .feedback-form .upload-document>div {
       width: 95%;
       text-align: right;
    } 
}