/* New Leads || Digital Marketing History ||  Social Media   
| Lead Purchage History ||  Followup History */

.new-leads .right h2 {
    color: #000;
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
}


.new-leads .right h6 {
    color: #000;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.new-leads .right h6 span {
    color: #17A2B8;
}

.new-leads .right .art-1 {

    background-color: #fff;
    padding-bottom: 1.2rem;

}

.new-leads .right .art-1>div:first-child,
.new-leads .right .art-2>div:first-child,
.new-leads .right .art-2 h4 {
    padding: 1.5rem 1.3rem 0.9rem 1.3rem;
    border-bottom: 1px solid #E4E4E4;
}

.new-leads .right .art-1 .cards {
    padding: 1.5rem 1.3rem;

}

.new-leads .right .art-1 .cards>div {
    width: 23%;
    height: 110px;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    align-items: center;
    justify-content: center;

}

.new-leads .right .art-1 .cards>div:not(:last-child) {
    border-radius: 4px;
    border: 1px solid #E2E5EC;
    background: #F1F2F5;
    text-align: center;
    height: 110px;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    align-items: center;
    justify-content: center;
}

.new-leads .right .art-1 .cards>div h5 {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
}

.new-leads .right .art-1 .cards>div span {
    color: #17A2B8;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.new-leads .right .art-1 .cards>div button {
    border: 1px solid;
    padding: 0.9rem;
    width: 95%;
    color: #FFF;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    background-color: #00B987;
    border-radius: 4px;
}

/* right art2  */

.new-leads .right .art-2 {
    background-color: #fff;

}

.new-leads .right .art-2 .table-div,
.dm-history .right .art-2 .table-div {
    padding: 0 1rem 1rem 0.8rem;

}

.new-leads .right .art-2 table thead th {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
}

.new-leads .right .art-2 table thead>tr>th:first-child {
    width: 11%;
}

.new-leads .right .art-2 table thead .product {
    width: 15%;

}


.new-leads .right .art-2 table tr {
    /* text-align: center; */
    vertical-align: middle;
    padding: 0.8rem 1.3rem;
    border-bottom: 1px solid #E4E4E4;

}

.new-leads .right .art-2 table thead>tr th {
    border-bottom: 1px solid #E4E4E4;
}

.new-leads .right .art-2 table tbody td {
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
}

.new-leads .right .art-2 table tbody td:last-child button {
    background: #FFB800;
    padding: 0.45rem 0.7rem;
}

.overf-div {
    max-width: 100%;
    overflow-y: auto;
    margin: 0 1.2rem;
}

.new-leads .right .art-2 .table-div {
    min-width: 900px;
}

/* ---------------------------------------------------------
| Digital Marketing History 
------------------------------------------------ */

.dm-history .right .art-2 table tbody td:last-child button,
.social-media .right .art-2 table tbody td:last-child button {
    padding: 0.5rem 0.6rem;
    border-radius: 50%;
    background-color: #1EE0AC33;
}

.dm-history .right .art-1 .cards>div {
    width: 19%;
    border: 1px solid #E2E5EC;
}

/* -------------------------
| Social Media  
-----------------------*/


.social-media .right .art-1 .cards>div,
.nleadpurch-history .right .art-1 .cards>div {
    border: 1px solid #E2E5EC;

}

.social-media .right .art-2 table td.check-inp {
    text-align: center;
}

.social-media .right .art-2 table tbody td img {
    max-width: 61px;
    max-height: 41px;
}


/* -------------------------------------
| Lead Purchage History
 -----------------------------------*/
input:focus {
    outline: none;
}

.nleadpurch-history .right .art-2 .tcontent p {
    color: #000;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0;
}

.nleadpurch-history .right .art-2 .tcontent>div input {
    padding: 0.6rem;
    border: 1px solid lightgray;
    border-radius: 0.4rem;
}

.nleadpurch-history .right .art-2 .tcontent>div input:focus-visible {
    outline: none;
}


.nleadpurch-history .right .art-2 .tcontent>div button {
    color: #FFF;
    font-size: 14px;
    font-weight: 700;
    padding: 0.6rem 3rem;
    border-radius: 0.3rem;
    background-color: #00B987;
}

.nleadpurch-history .right .art-2 .tab-btns button {
    background-color: #17A2B8;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    padding: 0.7rem 3rem;
}

.nleadpurch-history .right .art-2 .tab-btns {
    border-bottom: 1px solid #E4E4E4;
    margin: 1rem auto 0.5rem auto;
    padding-bottom: 1rem;
}

.active-tbtn {
    background-color: #176496 !important;
}



.nleadpurch-history .right .art-2 table tbody .action {
    gap: 0.5rem;
}

.nleadpurch-history .right .art-2 table tbody .action button {
    padding: 0.35rem 0.5rem !important;
    border-radius: 50%;
    background: #1EE0AC33 !important;
}

.nleadpurch-history .right .art-2 table tbody .action button:last-child {
    background: #17A2B866 !important;
}

.nleadpurch-history .right .art-2 table tbody .status span {
    background: #00B9871A;
    padding: 0.4rem 0.6rem;
    color: #00B987;
    font-size: 13px;
    font-weight: 400;
    border-radius: 0.4rem;
}


/* Change Lead----------------------- */

.change-lead {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #0000008f;
    z-index: 999;
}

.change-lead .fcontent {
    max-width: 500px;
    min-width: 320px;
    background-color: #fff;
    margin: auto;
    border-radius: 0.4rem;
    margin-top: 10%;

}

.change-lead .top-cont {
    padding: 1rem 1.3rem;
    border-bottom: 1px solid #555;
}

.change-lead .fcontent .top-cont h6 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
}

.change-lead .fcontent button {
    font-size: 14px;
    font-weight: 600;
}

.change-lead .cont-body {
    padding: 0.5rem 1.3rem 1rem;
}

.change-lead .cont-body form input,
.change-lead .cont-body form select {
    padding: 0.7rem;
    width: 70%;
    border-radius: 0.3rem;
    border: 1px solid #EEE;
}

.change-lead .cont-body form label {
    color: #555;
    font-size: 14px;
    font-weight: 500;
}

.change-lead .cont-body form button {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    background-color: #FFB800;
    padding: 0.7rem 2.5rem;
    border-radius: 4px;

}

.change-lead .cont-body form button.cancel {
    color: #fff;
    background-color: #555;
}

/* -------------------------------------
| Followup History
 -----------------------------------*/

.followup-history .right .cards-div .card-cont {
    padding: 1rem;
    border-radius: 0.4rem;
    border-radius: 4px;
    border: 1px solid #E2E5EC;
    background: #F1F2F5;
    margin: 1.2rem;
}

.followup-history .right .cards-div .top-cont {
    margin-bottom: 0.9rem;
}

.followup-history .right .cards-div .top-cont h4 {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
}

.followup-history .right .cards-div .top-cont p {
    color: #555;
    font-size: 16px;
    font-weight: 400;
}

.followup-history .right .cards .cont-body p {
    color: #555;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}


/* ----------------------------------------
| Graphic Images
--------------------------------------------- */
.graphic .right .art-2 .tab-btns button {
    padding: 0.7rem;
    width: 200px;
    background-color: #17A2B866;
    color: #353535;
    font-size: 13px;
    font-weight: 700;
}

.graphic .active-tab {
    color: #fff !important;
    background-color: #17A2B8 !important;
}

.graphic .graphics-div .pg-card button {
    color: #FFF;
    text-align: center;
    font-family: Rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    background-color: #17A2B8;
    padding: 0.75rem;
    width: 100%;
    border-radius: 0.3rem;
}

.graphic .graphics-div button.bg-lgreen{
    background-color: #00B987;
}



/* ----------------------------------------
| Media Query
----------------------------------------------- */


@media(max-width: 768px) {
    .new-leads .main-cont {
        flex-direction: column;
        gap: 1rem;
    }
}

@media(max-width: 575px) {
    .new-leads .right .art-1 .cards>div h5 {
        font-weight: 600;
    }

    .new-leads .right .art-1 .cards>div button {
        font-size: 17px;
        line-height: 17px;
    }

    .nleadpurch-history .right .tcontent{
        flex-direction: column;
        gap: 0.7rem;
        align-items: start !important;
    }



}

@media(max-width: 490px) {
    .new-leads .right .art-1 .cards{
        flex-wrap: wrap;
    }
    .new-leads .right .art-1 .cards>div {
        width: 48%;
    }
    .new-leads .right h2 {
        font-size: 18px;
        font-weight: 500;
    }
    .nleadpurch-history .right .tcontent >div:first-child{
        flex-wrap: wrap;
    }
}

@media(max-width: 416px) {

.new-leads .right h6 {
    font-size: 14px;
}

}
