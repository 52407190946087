/* Start Second Common  sec */
.second-common h2 {
  color: #000;
  font-size: 22px;
  font-weight: 600;
}
.second-common h4 {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
}
.second-common .categories .right .card {
  width: 291px;
  /* height: 165px; */
  background-color: #f8f8f8;
}

.second-common .categories .left > img{
  width: 320px;
  height: 346px;
}

.second-common .categories .right .card a {
  color: #1351c1;
  font-size: 12px;
  /* text-decoration-line: underline !important; */
  font-weight: 600;
  /* text-wrap:  nowrap; */
  text-decoration: none;
}
.second-common .categories .content-img .content1{
  width: 200px;
  margin-right: 10px;
}

.second-common .categories .content-img .content1 p {
  width: 100%;
  color: #747373;
  font-size: 14px;
  /* margin-bottom: 0rem; */
  text-wrap: wrap;
  font-weight: 400;
  cursor: pointer;
  line-height: 17px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #80808021;
}
.second-common .categories .content-img .content1 p:hover{
  color: #0085FF;
}
.cat-bg{
  background-color: #e9e9e9;
  padding-top: 50px;
  padding-bottom: 50px;
}
.electronic-cosmatic-section{

}
/* right */

/* End Second Common  sec */






/*-------------------------------
|  Media Query 
---------------------------------------------------*/
@media(max-width:1399px) {
    .second-common .categories .right .card {
        width: 264px;
        height: auto;
        padding: 0.7rem !important;
        background-color: #f8f8f8;
    }
}

@media(max-width:1199px) {
    .second-common .categories .right .card {
        width: 220px;

    }
}


@media(max-width:992px) {
    .second-common .categories .left > img {
        width: 100%;
        height: 250px;
        margin-bottom: 1.3rem;
    }

    .second-common .categories .right .card {
        width: 217px;

    }

    .categories .row {
      gap: 1rem;
    }
    .categories .row .left{
      text-align: center;
    }
    .categories .row .right .content-img .image{
      margin: auto;
    }
   
}

@media(max-width: 490px) {
    .second-common .categories .right .card {
        width: 100%;
    }
}
.product-card-design{
  width: 100%;
  height: 200px;
  border-radius: 7px;
  padding: 10px;
  border: 1px solid gray;
  margin-bottom: 30px;
}
.second-common .categories{
  border-top: 3px solid blue;
  border-radius: 4px;
  /* margin-top: 40px; */
  /* border-left: 1px solid rgb(207, 207, 207);
  border-right: 1px solid rgb(207, 207, 207);
  border-bottom: 1px solid rgb(207, 207, 207); */
}

.text-exceed{
  margin-top: -20px !important;
}