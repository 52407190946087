/* Start banner  */
.seller-banner h2 {
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}

.seller-banner .content h2 span {
  font-size: 42px;
  font-weight: 600;
  line-height: 50px;
}

.seller-banner .content a {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
  border: 1px solid #1351c1;
  background: #1351c1;
  width: fit-content;
  padding: 0.5rem 1rem;
}

/* Highlited Points  */

.seller-banner .highlited-points .points span {
  color: #1351c1;
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
}

.seller-banner .highlited-points .points p {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.seller-banner .highlited-points .points-parent {
  overflow-x: scroll;
  max-width: 90%;
  margin: auto;
  top: -25px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 10px #cbdaf9;

}

.seller-banner .highlited-points .points-parent::-webkit-scrollbar {
  display: none;
}

.seller-banner .highlited-points .points {
  min-width: 800px;
  margin: auto;
  background: #fff;
  padding-top: 0.6rem;
  border-radius: 0.6rem;
  z-index: 99;
  /* box-shadow: 0 0 10px #cbdaf9; */
  display: flex;
  margin: auto;
}

.seller-banner .highlited-points .points>div {
  width: 24%;
  text-align: center;
  position: relative;

}

.seller-banner .highlited-points .points>div:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 20%;
  right: 0;
  width: 2px;
  height: 50%;
  background-color: #1351c196;
}

/* End banner  */

/* Start Why Sell on apna shaher   */

/* .why-sell h3 { */
h3 {
  color: #000;
  font-size: 40px;
  font-weight: 600;
  line-height: 42px;
}

/* .why-sell h3 span { */
/* h3 span {
  color: #1351c1;
} */

.why-sell .heading-para p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}

.why-sell .cards h5 {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0;
}

.why-sell .cards P {
  color: #555;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
}

.why-sell .right-img img {
  height: auto;
  border-radius: 10px;
}

/* Start Why Sell on apna  shaher*/

/* Start Service Provider marketplace (SPM)  */

.spm-banner {
  background-color: #002a5b;
}

.spm-banner .right-content h3 {
  color: #fff;
  font-size: 55px;
  font-weight: 600;
  line-height: 54px;
  letter-spacing: 2.75px;
  margin-bottom: 1rem;
}

.spm-banner .right-content p {
  color: #f4f4f4;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

.spm-banner .spm-btns a {
  display: flex;
  padding: 0.6rem 1.5rem;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  border-radius: 0.4rem;
  background: #fff;
  color: #000;
}


/* End Service Provider marketplace (SPM)  */
.spm-banner .spm-btns .contact-us-btns{
  width: 230px;
  display: flex;
  justify-content: center;
  border: 1px solid #002a5b;
  color: #002a5b;
  border: 5px;
  cursor: pointer;
  text-align: center;
}
.spm-banner .spm-btns .sell-on-btns{
  width: 230px;
  display: flex;
  justify-content: center;
  border: 1px solid #002a5b;
  color: #002a5b;
  border: 5px;
  cursor: pointer;
  text-align: center;
}
.spm-banner .spm-btns .sell-on-btns:hover{
  border: 1px solid #ffffff;
  background-color: #002a5b;
  color: #fff;
}
.spm-banner .spm-btns .contact-us-btns:hover{
  border: 1px solid #ffffff;
  background-color: #002a5b;
  color: #fff;
}
/* Start Seller Testimonial section  */
.seller-testimonial {
  background-color: #1351c11f;
}

.seller-testimonial .slick-slider button.slick-prev::before {
  /* content: url("../../public/assets/Icons/prev.png"); */
  content: "<";
}

.seller-testimonial .slick-slider button.slick-next::before {
  content: ">";
}

.seller-testimonial .slick-slider button.slick-prev::before,
.seller-testimonial .slick-slider button.slick-next::before {
  border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #1351c1;
  padding: 0.1rem 1.2rem 0.3rem;
  display: flex;
  align-items: center;
  color: #5394fd;
  font-weight: 900;
  justify-content: center;
  font-size: 2rem;
}

/* End Seller Testimonial section  */

/* SB section4  */
.sb-section4 .cards .card-item {
  width: 23%;
}


.registor-marketplace .cards>div>img {
  max-width: 112px;
  max-height: 112px;
}


/* Start Seller Help  */
.seller-help p {
  color: #555;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.seller-help .form-container form input,
.seller-help .form-container form select {
  padding: 0.9rem 1rem;
  border: none;
  border: none;
  background-color: #fff;
  border-radius: 0.2rem;
  font-size: 13px;
  color: 13px;
  color: #555;
  width: 48%;

}

.seller-help .form-container form textarea {
  width: 98%;
  padding: 0.9rem 1rem;
  border-radius: 0.2rem;

}

.seller-help .form-container form button {
  width: 100%;
  padding: 0.6rem 5rem;
  font-size: 1.2rem;
  font-weight: 500;
  background: #002a5b;
  color: #fff;
  border-radius: 0.2rem;
  margin-top: 1rem;
}

/* End Seller Help  */









/* Start Media Query  */
@media (max-width: 1399px) {

  .sb-section4 .cards-parent {
    overflow-x: scroll;
  }
  .sb-section4 .cards-parent::-webkit-scrollbar {
      display: none;
  }

  .sb-section4 .cards {
    min-width: 1080px;
  }

}


@media (max-width: 1199px) {
  /* h3 {
      font-size: 33px;
    } */

  .seller-banner h2 {
    font-size: 32px;
  }

  .seller-banner h2 span {
    font-size: 34px;
  }

  /* SPM banner  */
  .spm-banner .right-content h3 {
    font-size: 47px;
  }

  .spm-banner .right-content p {
    font-size: 15px;
  }
}

@media (max-width: 992px) {
  .seller-banner .bg-white {
    height: 300px;
  }

  /* why sell   */
  .why-sell>.container {
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .why-sell .right-img img {
    height: 630px;
  }
  .seller-benifits-increase {
    width: 100%;
    height: auto !important;
}
.seller-accordian {
  width: 100%;
  height: auto;
  display: grid
;
  grid-template-columns: 1fr !important;
  gap: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 30px;
}
  /* SPM   */
  .spm-banner .spm-btns a {
    font-size: 14px;
  }

  .spm-banner .right-content h3 {
    font-size: 38px;
    line-height: normal;
  }

  .spm-banner .right-content p {
    font-size: 14px;
  }

  /* SB Section 4  */
  .sb-section4 .top-content p {
    width: 100% !important;
  }

  .sb-section4 .cards {
    justify-content: center;
  }





  /* Seller help  */

  .seller-help .container {
    flex-direction: column-reverse;
    gap: 1.5rem;
  }

  .seller-help .form-container form input {

    width: 49%;
  }

  .seller-help .form-container form textarea {
    width: 100%;
  }
}


@media(max-width:767px) {

  .seller-banner .highlited-points .points-parent {
    top: -37px;
  }

  .seller-banner .bg-white {
    height: auto;
  }

  .seller-banner h2 {
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 37px;
  }

  .seller-testimonial .slick-slider .slick-prev,
  .seller-testimonial .slick-slider .slick-next {
    display: none !important;
  }

}


@media(max-width:575px) {
  h3 {
    font-size: 33px;
  }
}

@media(max-width: 490px) {
  .why-sell .cards>div {
    flex-direction: column;
  }

  .subscribe .subscribe-content h2 {
    font-size: 25px;
  }

  .subscribe .subscribe-content p {
    font-size: 17px;
    line-height: 25px;
  }
}

@media(max-width: 416px) {
  .seller-banner .content h2 span {
    font-size: 34px;
  }

  /*spm  banner  */
  .spm-banner .right-content h3 {
    font-size: 33px;
  }

  .spm-banner .spm-btns a {
    font-size: 12px;
  }

  /* Seller help */
  .seller-help .form-container form>div {
    flex-direction: column;
    width: 100%;
  }

  .advertise-help .select-content select {
    width: 100% !important;
  }

  .seller-help .form-container form input {
    width: 100%;
  }

  .subscribe .subscribe-content .get-notify button {
    padding: 0.5rem 0.4rem;
    font-size: 13px;
  }

}

/* End Medai Query   */
.seller-benifits-increase{
  width: 100%;
  height: 700px;

}
.seller-accordian{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 6fr 6fr;
  gap: 30px;
  margin-top: 30px;
}
.seller-acco-left{
  width: 100%;
  height: 456px;
  overflow: auto;
 gap: 20px;

}
.seller-acco-left::-webkit-scrollbar{
  display: none;
}
.seller-accordian-tab{
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.144);

  margin-bottom: 20px;
  
}
.seller-accordian-tab-heading{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  
}
.seller-accordian-discription{
  padding:0px 20px 20px 20px;
}
.seller-right-image{
  width: 100%;
  height: 456px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.seller-right-image img{
  border-radius: 5px;
}

.seller-section-bg{
width: 100%;
height: auto;
background-color: #fff;
padding-top: 20px;
}