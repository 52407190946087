/* Start Build My startup services (BMSs)  */
.build-ms-services .cards-container {
    gap: 1rem;
}

.build-ms-services .cards-container .card {
    width: 32%;
    border: none;
    /* box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.02); */
    /* background-color: #e7e7e74d; */
}

.build-ms-services .cards-container .card .card-head{
display: flex;
flex-direction: column;
/* gap: 20px; */
}
.build-ms-services .cards-container .card .card-head p img{
    width: 325px !important;
    height: 220px !important;
    border-radius: 7px !important;
}
.build-ms-services .cards-container .card h4 {

    color: #000;
    font-family: Bahnschrift, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px !important;
}
.card-heading{
    width: 100%;
    height: auto;
    border-radius: 7px;
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.02);
    background-color: #e7e7e74d;
    padding: 10px;

}

/* End Build My startup services   */


.build-ms-form .form-div .stage {
    width: 100%;
    background: #1351C1;
}

.build-ms-form .form-div .stage span {
    text-align: center;
    padding: 0.8rem 0rem;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    display: inline-block;
    width: calc(100% / 3);
    height: 100%;
}
.build-ms-form .form-div .stage span:not(:last-child) {
 border-right: 2px solid #fff;
}

.build-ms-form .form-div .stage span.active {
    background: #FFAE00;
    color: #000;
    border-right: none;

}

.build-ms-form .form-div form input,
.build-ms-form .form-div form .company-confirm,
.build-ms-form .form-div form  select {
    border: none;
    outline: none;
    padding: 1rem;
    font-size: 16px;
    width: 48%;
}

.build-ms-form .form-div form  .alert-para{
    display: none;
}
.build-ms-form .form-div form input.full-address {
    width: 100%
}



.build-ms-form .form-div form button {
    background: #1351C1;
    color: #fff;
    padding: 12px;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 2px;
}



/* End Build My Startup Form==================== */

/* Recent Startup   */
.recent-startup-list .recent-stp {
    overflow-x: auto;
}
.recent-startup-list .recent-stp  .cards {
    min-width: 1080px;
}


/* Start Chart  */
.build-ms-chart .rounded-div {
    width: 116px;
    height: 88px;
    background: rgba(19, 81, 193, 0.12);
    border-radius: 0 0 2rem  2rem;
    margin-bottom: 2rem;
}

.build-ms-chart .left-content ul li {
    color: #555;
    font-size: 18px;
    font-weight: 500;
    padding: 1rem 1.2rem;
    border-radius: 0.5rem;

}

.build-ms-chart .left-content ul li span{ 
    background-color: #ffffff09;
  
}

/* .build-ms-chart .left-content ul li .arrow-icon { 
    display: none;
} */

.active-build-ms-stab{
    background: #fff ;
    color: #1754c2 !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.08);
    scale: 1.1;
}


/* right-contnet */
.build-ms-chart .right-content {
    background-color: #EDEDFB;
}

.build-ms-chart .right-content > div .bold-para {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

/* End Chart  */




/* Start Media Query   */
@media (max-width:1199px) {
    .build-ms-form .form-div .stage span {
        font-size: 14px;
    }
}

@media(max-width: 992px) {
    .build-ms-services .cards-container .card {
        width: 48%;
    }
    .build-ms-chart .rounded-div {
        width: 90px;
        height: 60px;
    }
}


@media(max-width:767px) {
    .build-ms-chart .right-content > div .bold-para {
        font-size: 14px;
    }
}
@media(max-width:460px) {
    .build-ms-services .cards-container .card {
        width: 99%;
    }

    .build-ms-form .form-div .stage span {
        font-size: 12px;
        padding: 0.5rem 0;
    }

    .build-ms-form .form-div form input,
    .build-ms-form .form-div form .company-confirm {
        width: 99%;
    }

    .build-sm-benefits .cards > div {
        flex-direction: column;
        gap: 1rem;
    }


}
.build-ms-comp2{
    background-color: #fff;
    padding: 2rem !important;
}

/* End Media Query   */
.build-my-startup-services-card{
width: 100%;
height: 500px;
display: grid;
grid-template-columns: 1fr 1fr;
gap: 20px;
}

.left-side-build{
    width: 100%;
    height: auto;
    display: flex;
    gap: 20px;
    flex-direction: column;
}
.build-left-side-content{
    width: 100%;
    height: 50px;
    display: flex;
   gap: 25px;
    flex-direction: column;
    
}
.build_text h1{
    color: #002a5b;
}
.build-left-number{
    display: flex;
}
.build-left-number h1{
margin: 0;
}
.build_service-bg{
    background-color: #f5f5f5;
    padding: 50px 0px;
}
/* .card-first{
    background-color: #002a5b07 !important;
}
.card-second{
    background-color: #005b3509 !important;
}
.card-third{
    background-color: #5b340005 !important;
}
.card-fourth{
    background-color: #37005b05 !important;
} */
 .build-ms-form{
    background-color: #002a5b;
    padding: 70px 0px;

    

 }
 .build-ms-form h3{
    color: #ffffff;
    font-size: 36px;
 }
 .build-ms-form p{
    color: #d8d7d7;
   
 }
 .build-ms-form .form-div{
  
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff;
 }
 .vertical-stepper-section1{
    width: 100%;
    height: 620px;
    display: grid
;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
 }
 .startup-recent-list-card{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    margin-bottom: 50px;
 }
 .startup-recent-card-design{
    width: 100%;
    height: auto;
    /* padding: 1rem; */
    border: 1px solid #cccccc;
    border-radius: 7px;
    overflow: hidden;
 }
 .startup-recent-logo{
    width: 70px;
    height: 70px;
    background-color: #1754c2;
    position: relative;
    right: -260px;
    top: -35px;
 }
 .startup-recent-content{
    width: 100%;
height: auto;
/* position: relative; */
margin-top: -72px;

}
.startup-recent-heading{
    width: 100%;
    height: auto;
    padding: 1rem;
}
.startup-recent-heading p{
    line-height: 18px;
}
.startup-contry{
    width: fit-content;
    padding: 2px 10px;
    border-radius: '3px';
    background-color: rgba(128, 128, 128, 0.192);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    gap: 5px;
    margin-top: 20px;
    /* border-bottom: 1px solid #cccccc; */

}
.startup-contry p{
    margin: 0px;
    font-size: 12px;
}
.startup-category-list{
    width: 100%;
    height: auto;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 10px;
}
.startup-visitor-section p{
    font-size: 14px;
    color: rgb(156, 156, 156);
    margin-bottom: 5px;
}
.startup-view-sec{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.startup-count{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.startup-line{
    width: 1px ;
    height: 40px;
    background-color: #cccccc;
}