/* Start Header -----------------------------------*/
/* top header ----------- */
.manage-services-header .theader select,
.manage-services-header .theader a {
    width: fit-content;
    color: #555;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.13px;
}

/* header--------- */
.manage-services-header .navbar {
    background-color: #000;
    height: 80px;
    padding: 0 2rem;
}

.manage-services-header .navbar .toggle-menu {
    display: none;
}

.manage-services-header .navbar form {
    width: 47%;
}

.manage-services-header .navbar form input {
    width: 100%;
    padding: 0.7rem 1.5em;
    border-radius: 1.5rem;
    background-color: #fff;
    border: none;
}

.manage-services-header .navbar form input:focus-visible {
    outline: none;
}

.manage-services-header .navbar form button {
    right: 0.3rem;
    top: 0.3rem;
    border-radius: 1.3rem;
    background-color: #FFB800;
    padding: 0.45rem 1.5rem;
    color: #000;
    font-size: 13px;
    font-weight: 450;
}

.manage-services-header .navbar .nav-links button::after {
    display: none;
}

.manage-services-header .navbar .nav-links ul .badge {
    width: 20px;
    height: 20px;
    top: -0.3rem;
    right: -0.8rem;
    font-size: 13px;
    background-color: #FFB800;
    display: flex;
    justify-content: center;
}

/* End Header ------------------------------------*/


/* Start main content---------- */
.manage-service .right .heading-div h2 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.manage-service .right .heading-div button {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    background: #00B987;
}


.manage-service .right h4 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.manage-service .right .table-div table {
    min-width: 800px;
}

.manage-service .right .table-div table th {
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.manage-service .right .table-div table td {
    color: #555;
    font-size: 13px;
    font-weight: 500;
    vertical-align: middle;
}

.manage-service .right .table-div table td.status span {
    padding: 0.4rem 1rem;
    background-color: rgba(0, 185, 135, 0.10);
    border-radius: 0.4rem;
}

.manage-service .right .table-div table .actions button {
    width: 30px;
    height: 30px;
}

.manage-service .right .table-div table .actions button img {
    height: 17px;
    width: 17px;
}

.manage-service .right .table-div .mng-overflow {
    width: 100%;
    overflow-x: auto;
}

/* End main content---------- */


/* Start Add Service Form  */


.add-service form label {
    white-space: nowrap;
    color: #555;
    font-size: 14px;
    font-weight: 400;
}

.add-service form iframe {
    height: 190px !important;
}

.add-service form input[type="text"],
.add-service form input[type="number"],
.add-service form select {
    padding: 0.83rem;
    width: 65%;
    border: 1px solid #dedede;
    border-radius: 0.4rem;

}

.add-service form input[type="text"]:focus-visible,
.add-service form input[type="number"]:focus-visible,
.add-service form select:focus-visible {
    border: 1px solid #dedede;
}

.add-service form div.inp-div,
.add-service form .add-details .form>div>div {
    width: 48%;
    justify-content: space-between;
    margin: 0.6rem 0;


}

.add-service form .add-details .form div.short-disc>div,
.add-service form .add-details .form div.what-include>div {
    width: 100%;
}

.add-service form .fpart1>div.sname>input,
.add-service form .fpart1>.stitle>input {
    width: 83%;
}

.add-service form .scat-img .input-group,
.add-service form .sgallery .add-img-div-parent {
    width: 83%;
}

.add-service form .scat-img .input-group input {
    padding: 0.83rem;
}

.add-service .browse-scat-img .input-group span {
    color: #FFF;
    font-size: 15px;
    font-weight: 500;
    background-color: #17A2B8;
    text-transform: capitalize;
}




.add-service form .sgallery .add-img-div {
    width: 17%;
    position: relative;
}

.add-service form .sgallery .add-img-div input {
    width: 100%;
    position: relative;
    z-index: 5;
    padding: 1.2rem 0;
    opacity: 0;
}

.add-service form .sgallery .add-img-div>div {

    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;
    border: 1px solid;
    text-align: center;
    padding-top: 0.5rem;
    border: 1px solid #E2E5EC;
    border-radius: 0.4rem;
}

.add-service form .sgallery .add-img-div>div img {
    width: 17px;
}

.add-service form .sgallery .add-img-div>div p {
    color: #555;
    font-size: 12px;
    font-weight: 500;
    margin-top: 0.3rem;
}

.add-service .features>div>label {
    margin-bottom: 1.5rem;
    color: #000;
    font-size: 16px;
    font-weight: 600;
}
.add-service .features>div>input:not(.feature-inp) {
    margin: auto;
    height: 23px;
    width: 56px;
    background-color: #CFD4D3;
}
.add-service .features>div>input:focus {
    outline: none;
    border: none;
}

.add-service .features> .form-switch .form-check-input:checked {
    background-image: url("../../public/assets/Icons/lgreen-circle2.png");
    border: none;

}



.add-service .features>div input[type="radio"] {
    height: 1.2rem;
    margin-top: 1rem;
}

.add-details .features>div button {
    color: #555;
    font-size: 16px;
    font-weight: 500;
}

/* End Add Service Form  */




/* Sart media ^^^^^^^^^^^^^^^^^^^^^^^^^^^ */


@media(max-width: 992px) {

    .add-service form div.inp-div,
    .add-service form .add-details .form>div>div,
    .add-service form .fpart1>div.sname,
    .add-service form .fpart1>.stitle,
    .add-service form .scat-img > div {
        flex-direction: column;
        align-items: start !important;
        gap: 0.3rem;

    }

    .add-service form input[type="text"],
    .add-service form input[type="number"],
    .add-service form select {
        width: 98%;
    }

    .add-service form .scat-img .input-group, .add-service form .sgallery .add-img-div-parent {
        width: 100%;
    }

   

    .add-service form .fpart1>div.sname > input,
     .add-service form .fpart1>.stitle> input {
        width: 98%;
     }
}

@media(max-width: 767px) {
    .manage-services-header .theader {
        padding: 0 0.7rem;
    }

    .manage-services-header .navbar {
        height: 70px;
    }

    .manage-services-header .navbar form input {
        padding: 0.5rem 1.2rem;
    }

    .manage-services-header .navbar form button {
        padding: 0.3rem 1.1rem;
        top: 0.25rem;
    }
}

@media(max-width: 460px) {
    .add-service form .fpart1>div,
    .add-service form .add-details .form >div {
        flex-direction: column;
        align-items: start !important;

    }

    .add-service form div.inp-div, .add-service form .add-details .form>div>div {
        width: 100%;
    }
}


@media(max-width: 416px) {

    .manage-services-header .theader select,
    .manage-services-header .theader a {
        font-size: 12px;
    }
}


@media(max-width: 390px) {
    .manage-services-header .theader>div {
        justify-content: space-between;
        width: 100%;
    }

    .manage-services-header .theader>div:last-child {
        padding: 0 0.7rem;
    }
}

/* End media ^^^^^^^^^^^^^^^^^^^^^^^^^^^ */