.header-section {
  display: grid;
  grid-template-columns: 31% 36% 31%;
  align-items: baseline;
}

.header-right-section {
  display: flex;
  justify-content: end;
  gap: 0px;
  position: relative;
  /* top: 7px; */
}

.dropdown-options {
  padding-left: 0rem;
  list-style-type: none;
  font-size: 14px;
}

.header-container {
  padding: 10px;
}

/* .banner-links input {
    border-top-left-radius: 40px !important;
    border-bottom-left-radius: 40px !important;
} */

.banner-links span {
  border-radius: 40px !important;
  position: relative;
  right: 20px;
}

/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Mobile Header Css ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */

.mobile-header-parent {
  display: none;
}

.mobile-header .bg-color {
  width: 98%;
  height: 100vh;
  position: absolute;
  top: 0;
  background: #80808085;
  z-index: 12;
  display: none;
  transition: 0.5s ease;
}

.mobile-header {
  max-width: 460px;
  z-index: 99;
  /* display: none; */
}

.mobile-header .login > button {
  border: none;
}
.mobile-header .login ul.dropdown-menu {
  width: 250px;
  padding: 19px;
  background-color: #fff;
}

.mobile-header .login ul.dropdown-menu h6 {
  color: #000;
  font-family: Bahnschrift, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
}

.mobile-header .login ul.dropdown-menu li a {
  color: #555;
  font-size: 14px;
}

.mobile-header .login ul.dropdown-menu li {
  padding: 0.2rem 0;
  border-bottom: 1px solid #f0f0f0;
}
.mobile-header .right-links select {
  font-size: 14px;
  padding: 0.2rem;
  border: none;
  padding-left: 0.8rem;
  /* width: fit-content; */
}
.mobile-header .right-links select::before {
  padding-left: 0.9rem;
}



.mobile-header .right-links .dropdown-toggle::after {
  display: none;
}

/* ^^^^^^^^^^^^^^Moibe Sidebar ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */

.msbar-parent {
  max-width: 460px;
  transition: 0.4s ease;
  position: absolute;
  top: 0;
  left: -100%;
  background-color: #0000006b;
}
.mobile-sidebar {
  max-width: 321px;
  padding: 1.4rem 1rem;
  box-shadow: 0 0 10px grey;
  height: 100vh;
  z-index: 9999;
  background-color: #fff;
  position: fixed;
  top: 0;
}

.mobile-sidebar .sb-header .user h3 {
  font-size: 17px;
  font-weight: 600;
  margin: 0;
}

.mobile-sidebar .sb-header .user p {
  color: #1351c1;
  font-size: 14px;
  font-weight: 500;
}

.mobile-sidebar .sb-hero > p {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.mobile-sidebar .sb-hero .save-btn {
  background: #ffd25e;
  border-radius: 1rem;
}

.mobile-sidebar .sb-hero > p span {
  color: #d60d0d;
  font-size: 17px;
  font-weight: 700;
}

.mobile-sidebar .sb-hero > .sell-btn {
  border-radius: 10px;
  border: 1px solid #ffae00;
  background: rgba(255, 184, 0, 0.63);
  height: 42px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}

.mobile-sidebar .sb-header .ship-to > ul > input {
  border: 1px solid #e3e3e3;
  padding: 0.3rem;
  border-radius: 0.4rem;
}

.mobile-sidebar .sb-header .ship-to > ul > button{
  border-radius: 0.4rem;
  background: #ffd25e;
  color: #fff;
}

.mobile-sidebar .sb-main .accordion-header {
  border-bottom: 1px solid #eeeeee !important;
}

.mobile-sidebar .sb-main .accordion-button {
  color: #666;
  font-size: 17px;
  font-weight: 600;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  padding-left: 0;
  background: transparent;
}
.mobile-sidebar .sb-main > div.accordion {
  max-height: 270px;
  overflow-y: auto;
}

.mobile-sidebar .accordion .accordion-item {
  padding-left: 0.5rem;
}




/*^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ 
| Media Query 
^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */

@media (max-width: 460px) {
  .mobile-header-parent {
    display: block !important;
  }

  .header-section,
  .header-container {
    display: none !important;
  }
}

@media (max-width: 416px) {
  /* mobile Header --- */
  .mobile-header > button > img {
    width: 22px;
  }
  .mobile-header > a > img {
    width: 120px;
  }
}
/* new css */
.top_header{
  width: 100%;
  height: auto;
  background-color: #1351c1;
  border-bottom: 1px solid rgba(163, 163, 163, 0.37) !important;
  /* border-top: 3px solid #f3f3f3; */
}
.text-key{
  display: flex !important;
  padding-left: 270px;
  align-items: end !important;
}
.text-key p{
  margin: 0px !important;
}
.searchbox_field{
 
  display: flex;
  align-items: center;
  padding-left: 170px;
  width: 55%;
  /* margin-left: -158px; */
  /* margin-top: 5px; */
}
.searchbox_field input{
  width: 450px;
  border-radius: 9px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.searchbox_field select{
  width: 40px;
  height: 40px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  -webkit-appearance: none; /* Remove default dropdown styling */
  -moz-appearance: none;
  appearance: none;
  padding-right: 2.5rem; /* Space for the custom icon */
  background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzUaYv-ilj0HldAl-KBgGSQyvaT33uIsXjSw&s'); /* Replace with your icon URL */
  background-repeat: no-repeat;
  background-position: right 1rem center; /* Position the icon */
  background-size: 1rem; /* Adjust icon size */
  cursor: pointer;
}
.searchbox_field select option{
  width: 300px;
  padding: 20px;
}
.search_icon{
  margin-top: -10px;
  margin-left: -40px;
}
.list_business{
  display: flex;
  align-items: center;
}
.list_business button{
  width: auto;
  height: 35px;
  background-color: #1351c1;
  border-radius: 5px;
  border: none;
  color: #fff;
  margin-top: 5px;
}
.dropdown-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-menu.right {
  left: 0;
}

.dropdown-menu.left {
  right: 0;
}

.dropdown-arrow {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: white;
  transform: rotate(45deg);
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

.dropdown-arrow.right {
  right: 10px;
}

.dropdown-arrow.left {
  left: 10px;
}
